import config from '@epi-config';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PagePanel, Box, Text } from 'edenred-ui';
import { useParams } from 'react-router-dom';

import {
  createBenefitGroup,
  getBenefitGroups,
  updateBenefitGroup,
  getActiveBenefits
} from '@epi-actions/api';
import { slideoutMode } from '@epi-constants/slideoutMode';
import {
  IDetail,
  IManageBenefitGroups
} from '@epi-models/containers/ManageBenefitGroups';
import {
  selectBenefitGroups,
  selectIsBenefitGroupsLoaded,
  selectIsBenefitGroupsError
} from '@epi-selectors/benefitGroups';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import {
  getLunchAmounts,
  getRecreationalAmounts
} from '@epi-selectors/settings';
import { BenefitType } from '@epi-constants/benefitTypes';
import { BenefitGroupTile } from '@epi-shared/components';

import { getDailyLimitSelectFieldOptions } from './helpers';
import {
  AddBenefitGroupTile,
  BenefitGroupDetail,
  CreatedGroupNotifications
} from './components';

const viewModeInitialState = {
  mode: slideoutMode.view,
  expanded: false,
  currentGroupId: null,
  createdGroupNotification: false,
  createdGroupSuggestion: false
};

const createModeInitialState = {
  mode: slideoutMode.create,
  expanded: true,
  currentGroupId: 0,
  createdGroupNotification: false,
  createdGroupSuggestion: false
};

function ManageBenefitGroups({
  benefitGroups,
  isBenefitGroupsLoaded,
  isBenefitGroupsError,
  lunchAmounts,
  recreationalAmounts
}: IManageBenefitGroups) {
  const { benefitGroup } = useParams();
  const dispatch = useDispatch();
  const isBenefitAvailable =
    benefitGroup === BenefitType.Lunch ||
    (benefitGroup === BenefitType.Recreational && config.enableVirikeBenefit);

  useEffect(() => {
    if (!isBenefitAvailable) {
      dispatch(goToPage(routerPaths.notFound));
    } else {
      dispatch(getBenefitGroups(benefitGroup));
    }
  }, []);

  useEffect(() => {
    dispatch(getActiveBenefits());
  }, [benefitGroups]);

  const { t } = useTranslation();

  const [detail, setDetail] = useState<IDetail>(viewModeInitialState);

  const handleViewDetail = id => {
    setDetail({
      ...detail,
      mode: slideoutMode.view,
      expanded: true,
      currentGroupId: id
    });
  };

  const handleCloseDetail = () => {
    setDetail(viewModeInitialState);
  };

  const handleEditDetail = id => {
    setDetail({
      ...detail,
      mode: slideoutMode.edit,
      expanded: true,
      currentGroupId: id
    });
  };

  const handleUpdateBenefitGroup = groupData => {
    setDetail({
      ...detail,
      mode: slideoutMode.view,
      expanded: false,
      currentGroupId: null
    });
    dispatch(updateBenefitGroup(groupData, benefitGroup));
  };

  const handleOpenCreateDatail = () => {
    setDetail(createModeInitialState);
  };

  const handleCreateBenefitGroup = groupData => {
    setDetail({
      ...detail,
      mode: slideoutMode.view,
      expanded: false,
      currentGroupId: null,
      createdGroupNotification: true,
      createdGroupSuggestion: true
    });
    dispatch(createBenefitGroup(groupData, benefitGroup));
  };

  const handleCancelDetail = () => {
    setDetail({
      ...detail,
      mode: slideoutMode.view,
      expanded: false,
      currentGroupData: null
    });
  };

  const benefitGroupTiles = benefitGroups.map((groupDetails, index) => (
    <BenefitGroupTile
      key={groupDetails.groupId}
      groupName={groupDetails.groupName}
      maxAmountPerPeriod={groupDetails.maxAmountPerPeriod}
      index={index}
      onTileClick={() => handleViewDetail(groupDetails.groupId)}
      onEditClick={() => handleEditDetail(groupDetails.groupId)}
      benefitType={benefitGroup}
    />
  ));

  const currentBenefitGroup = useMemo(
    () => benefitGroups.find(group => group.groupId === detail.currentGroupId),
    [detail.currentGroupId, benefitGroups]
  );

  const getAmounts = () => {
    if (benefitGroup === 'lunch') return lunchAmounts;
    if (benefitGroup === 'recreational') return recreationalAmounts;
    return [];
  };

  const dailyLimitOptionsIncludingCurrentlySelected = useMemo(() => {
    return getDailyLimitSelectFieldOptions(
      getAmounts(),
      currentBenefitGroup?.maxAmountPerPeriod
    );
  }, [lunchAmounts, currentBenefitGroup]);

  const shouldShowAddBenefitTile = benefitGroups.length === 0;

  return (
    <PagePanel
      title={t(`components.manage_benefits.new.benefits.${benefitGroup}`)}
      backIcon
      backIconLink={t('components.manage_benefit_lunch.back_link')}
    >
      <CreatedGroupNotifications
        detail={detail}
        isBenefitGroupsError={isBenefitGroupsError}
        setDetail={setDetail}
      />
      {!(detail.createdGroupNotification || detail.createdGroupSuggestion) && (
        <Box maxWidth={860} pb={5}>
          <Text light>{t('components.manage_benefit_lunch.subtitle')}</Text>
        </Box>
      )}
      {isBenefitGroupsLoaded && (
        <Box
          display="flex"
          flexWrap="wrap"
          id={`employee-benefits-${benefitGroup}-group-tiles`}
        >
          {benefitGroupTiles}
          {shouldShowAddBenefitTile && (
            <AddBenefitGroupTile onClick={handleOpenCreateDatail} />
          )}
        </Box>
      )}
      {isBenefitAvailable && (
        <BenefitGroupDetail
          detail={detail}
          onClose={handleCloseDetail}
          onSave={handleUpdateBenefitGroup}
          onCancel={handleCancelDetail}
          onCreate={handleCreateBenefitGroup}
          onEdit={handleEditDetail}
          benefitGroup={currentBenefitGroup}
          benefitType={benefitGroup}
          dailyLimitOptions={dailyLimitOptionsIncludingCurrentlySelected}
          key={currentBenefitGroup?.groupId}
        />
      )}
    </PagePanel>
  );
}

const connectToStore = connect(state => ({
  benefitGroups: selectBenefitGroups(state),
  isBenefitGroupsLoaded: selectIsBenefitGroupsLoaded(state),
  isBenefitGroupsError: selectIsBenefitGroupsError(state),
  lunchAmounts: getLunchAmounts(state),
  recreationalAmounts: getRecreationalAmounts(state)
}));

export default connectToStore(ManageBenefitGroups);
