import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  SlideoutPanelComponent as Slideout,
  Box,
  SimpleButton,
  EditIcon,
  Heading3,
  TextField,
  Text
} from 'edenred-ui';
import styled from 'styled-components';

import { slideoutMode } from '@epi-constants/slideoutMode';
import { BenefitType } from '@epi-constants/benefitTypes';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import {
  TRANSFER_PAGE_QUERY_NAME,
  TransferPageType
} from '@epi-constants/transferPage';
import { IBenefitGroupDetail } from '@epi-models/containers/ManageBenefitGroups';
import { CloseDetailDialog } from '@epi-shared/components';

import {
  ManageBenefitLunchFormFields,
  mapFormToBenefitGroup
} from '../../helpers';
import { BenefitGroupDetailActions } from '../BenefitGroupDetailActions';
import { BenefitGroupNameField } from '../BenefitGroupNameField';
import { DailyLimitField } from '../DailyLimitField';
import { CloseDetailVirike } from '../CloseDetailVirike';
import { TransferMoneyReminder } from '../TransferMoneyReminder';
import { DetailDailyLimitStatement } from '../DetailDailyLimitStatement';

const StyledBox = styled(Box)`
  // todo: temp solution - after updating the ui use the formfield's optional flexbasis prop
  span {
    margin-right: 20px;
  }
`;

export function BenefitGroupDetail({
  detail,
  benefitGroup,
  benefitType,
  dailyLimitOptions,
  onClose,
  onSave,
  onCreate,
  onCancel,
  onEdit
}: IBenefitGroupDetail) {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [showRedirectConfirmation, setShowRedirectConfirmation] =
    useState(false);
  const [isRedirectToLoadBenefit, setIsRedirectToLoadBenefit] = useState(false);
  const [isSubmitOnRedirectConfirmation, setIsSubmitOnRedirectConfirmation] =
    useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [ManageBenefitLunchFormFields.BenefitGroupName]: benefitGroup
        ? benefitGroup.groupName
        : '',
      [ManageBenefitLunchFormFields.DailyLimit]: benefitGroup
        ? benefitGroup.maxAmountPerPeriod
        : dailyLimitOptions[0].id
    }
  });

  const {
    formState: { isValid, isDirty, dirtyFields },
    watch,
    getValues
  } = form;

  const selectedDailyLimit = watch(ManageBenefitLunchFormFields.DailyLimit);
  const isVirikeBenefit = benefitType === BenefitType.Recreational;
  const isSubmitAndShowRedirectConfirmation =
    showRedirectConfirmation && isSubmitOnRedirectConfirmation;

  const handleSaveButton = values => {
    const newBenefitGroup = mapFormToBenefitGroup(
      values,
      benefitGroup,
      benefitType
    );

    if (detail.mode === slideoutMode.edit) {
      onSave(newBenefitGroup);
    }
    if (detail.mode === slideoutMode.create) {
      onCreate(newBenefitGroup);
    }
  };

  const confirmCloseWithoutSaving = () => {
    setShowCloseConfirmation(false);
    onClose();
  };

  const handleConfirmBenefitPopup = () => {
    confirmSaving();
    dispatch(
      goToPage(
        routerPaths.loadCompanyAccount +
          TRANSFER_PAGE_QUERY_NAME +
          TransferPageType.topupLoad
      )
    );
  };

  const handleCloseBenefitPopup = () => {
    confirmSaving();
    setShowCloseConfirmation(false);
  };

  const confirmSaving = () => {
    setShowCloseConfirmation(false);
    handleSaveButton(getValues());
  };

  const slideoutTitle =
    detail.mode === slideoutMode.create
      ? t('components.manage_benefit_lunch.detail_create_title')
      : t('components.manage_benefit_lunch.detail_view_title');

  const maxAmountPerPeriodDisplay = dailyLimitOptions
    ? Number(
        dailyLimitOptions.find(option => option.id === selectedDailyLimit)
          ?.display
      )
    : 0;

  const handleChangeTabToLoadBenefit = () => {
    if (detail.mode === slideoutMode.view || !isDirty) {
      return handleCloseSidebarAndRedirect();
    }
    setShowCloseConfirmation(true);
    setIsRedirectToLoadBenefit(true);
  };

  const handleCloseSidebarAndRedirect = () => {
    onClose();
    dispatch(
      goToPage(
        routerPaths.loadCompanyAccount +
          TRANSFER_PAGE_QUERY_NAME +
          TransferPageType.topupLoad
      )
    );
  };

  const handleCloseBenefitGroupDetailActions = () =>
    isDirty && detail.mode !== slideoutMode.view
      ? setShowCloseConfirmation(true)
      : onCancel();

  const handleClickBenefitGroupDetailActions = () => {
    if (isVirikeBenefit && showRedirectConfirmation) {
      setShowCloseConfirmation(true);
      setIsSubmitOnRedirectConfirmation(true);
    } else {
      confirmSaving();
    }
  };

  const handleCloseDetailVirike = () =>
    isSubmitAndShowRedirectConfirmation
      ? handleCloseBenefitPopup()
      : setShowCloseConfirmation(false);

  const handleSubmitDetailVirike = () => {
    if (isSubmitAndShowRedirectConfirmation) {
      return handleConfirmBenefitPopup();
    }
    if (isRedirectToLoadBenefit) {
      return dispatch(
        goToPage(
          routerPaths.loadCompanyAccount +
            TRANSFER_PAGE_QUERY_NAME +
            TransferPageType.topupLoad
        )
      );
    }
    confirmCloseWithoutSaving();
  };

  useEffect(() => {
    setShowRedirectConfirmation(!!dirtyFields?.maxAmountPerPeriod);
  }, [!!dirtyFields?.maxAmountPerPeriod]);

  return (
    <FormProvider {...form}>
      <Slideout
        title={slideoutTitle}
        expanded={detail.expanded}
        onClose={() =>
          isDirty && detail.mode !== slideoutMode.view
            ? setShowCloseConfirmation(true)
            : onClose()
        }
        actions={
          detail.mode !== slideoutMode.view ? (
            <BenefitGroupDetailActions
              saveDisabled={!isValid}
              onCancel={handleCloseBenefitGroupDetailActions}
              onClick={handleClickBenefitGroupDetailActions}
            />
          ) : null
        }
        closeButtonId="close-benefit-group-slideout"
      >
        <Box position="relative" pl={8.25} pt={2.5}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Heading3>
              {t(
                'components.manage_benefit_lunch.detail_benefit_settings_header'
              )}
            </Heading3>
            {detail.mode === slideoutMode.view && benefitGroup && (
              <SimpleButton
                id="edit-benefit-group"
                text={t('components.manage_benefit_lunch.detail_edit_btn_text')}
                icon={<EditIcon />}
                onClick={() => onEdit(benefitGroup.groupId)}
              />
            )}
          </Box>

          <StyledBox width={457} my={3.5}>
            <Box pb={4}>
              <TextField
                label={t(
                  'components.manage_benefit_lunch.detail_benefit_type_label'
                )}
                value={t(
                  `components.manage_benefits.new.benefits.${benefitType}`
                )}
                readOnly
              />
            </Box>
            <Box pb={4}>
              <BenefitGroupNameField
                label={t(
                  'components.manage_benefit_lunch.detail_group_name_label'
                )}
                readOnly={detail.mode === slideoutMode.view}
              />
            </Box>
            <Box pb={2}>
              <DailyLimitField
                label={t(
                  `components.manage_benefit_${benefitType}.detail_limit_label`
                )}
                readOnly={detail.mode === slideoutMode.view}
                options={dailyLimitOptions}
              />
            </Box>
          </StyledBox>
          <DetailDailyLimitStatement
            benefitType={benefitType}
            maxAmountPerPeriodDisplay={maxAmountPerPeriodDisplay}
            isVirikeBenefit={isVirikeBenefit}
          />
          {isVirikeBenefit && (
            <TransferMoneyReminder onClick={handleChangeTabToLoadBenefit} />
          )}
        </Box>
        {isVirikeBenefit ? (
          <CloseDetailVirike
            isOpen={showCloseConfirmation}
            handleCloseModal={handleCloseDetailVirike}
            titleText={
              isSubmitAndShowRedirectConfirmation
                ? t(
                    'components.manage_benefit_recreational.load_virike_benefit_popup_title'
                  )
                : t('components.manage_benefit_recreational.exit_pop_up_title')
            }
            text={
              isSubmitAndShowRedirectConfirmation ? (
                <Text
                  paragraph
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'components.manage_benefit_recreational.load_virike_benefit_popup_text'
                    )
                  }}
                />
              ) : (
                <Text paragraph>
                  {t('components.manage_benefit_recreational.exit_pop_up_text')}
                </Text>
              )
            }
            handleSubmit={handleSubmitDetailVirike}
            submitText={
              isSubmitAndShowRedirectConfirmation
                ? t(
                    'components.manage_benefit_recreational.load_virike_benefit_popup_submit'
                  )
                : t('components.manage_benefit_recreational.exit_pop_up_submit')
            }
            cancelText={
              isSubmitAndShowRedirectConfirmation
                ? t(
                    'components.manage_benefit_recreational.load_virike_benefit_popup_cancel'
                  )
                : t('components.manage_benefit_recreational.exit_pop_up_cancel')
            }
          />
        ) : (
          <CloseDetailDialog
            isOpen={showCloseConfirmation}
            handleCloseModal={() => setShowCloseConfirmation(false)}
            primaryAction={confirmCloseWithoutSaving}
            secondaryAction={confirmSaving}
            secondaryActionText={t(
              'containers.beneficiaries.close_detail_dialog.save'
            )}
          />
        )}
      </Slideout>
    </FormProvider>
  );
}
