import XLSX from 'xlsx';
import { format } from 'date-fns';

import { dateRangeModes } from '@epi-constants/downloadReports';
import { defaultDateFormat } from '@epi-constants/dateFormats';

import {
  getFirstDayOfCurrentMonth,
  getFirstDayOfLastMonth,
  getLastDayOfCurrentMonth,
  getLastDayOfLastMonth
} from './dateHelper';

export const setStartDate = (
  dateRangeMode: string,
  startDateValue: string | null = null
) => {
  if (dateRangeMode === dateRangeModes.lastMonth) {
    return getFirstDayOfLastMonth();
  }
  if (dateRangeMode === dateRangeModes.datePicker) {
    return startDateValue;
  }
  return getFirstDayOfCurrentMonth();
};

export const setEndDate = (dateRangeMode, endDateValue = null) => {
  if (dateRangeMode === dateRangeModes.lastMonth) {
    return getLastDayOfLastMonth();
  }

  if (dateRangeMode === dateRangeModes.datePicker) {
    return endDateValue;
  }
  return getLastDayOfCurrentMonth();
};

export const downloadReport = (state, report, payroll = false) => {
  const from =
    state.dateRangeMode === dateRangeModes.datePicker
      ? state.pickerStartDate
      : state.startDate;
  const to =
    state.dateRangeMode === dateRangeModes.datePicker
      ? state.pickerEndDate
      : state.endDate;
  const fromDate = format(
    new Date(payroll ? state.startDate : from),
    defaultDateFormat
  );
  const toDate = format(
    new Date(payroll ? state.endDate : to),
    defaultDateFormat
  );
  const title = payroll ? 'Payroll-' : '';
  const fileName = `${title}Report-${fromDate}-${toDate}`;
  const keys = Object.keys(report.data);
  const entries = Object.entries(report.data);
  const output: any[] = [];

  entries.forEach(entry => {
    const columnName = entry[0];
    const columnValues: any = entry[1];
    if (columnValues?.length > 0) {
      columnValues.map((val, index) => {
        if (typeof output[index] === 'undefined') {
          output[index] = { [columnName]: val };
        } else if (typeof output[index] === 'object') {
          output[index][columnName] = val;
        }
        return null;
      });
    } else {
      keys.forEach(val => output.push({ [val]: '' }));
    }
  });

  const wb = XLSX.utils.book_new();
  wb.SheetNames.push('Report');
  const ws = XLSX.utils.json_to_sheet(output);
  wb.Sheets.Report = ws;
  XLSX.writeFile(wb, `${fileName}.${state.downloadAs}`, {
    bookType: state.downloadAs
  });
};
