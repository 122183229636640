import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './Steps.scss';

export const Steps = ({
  pages,
  activePageId,
  navigationClick,
  isStepClickable
}) => {
  const activePageIndex = activePageId
    ? pages.findIndex(page => page.id === activePageId)
    : 0;

  return (
    <Container fluid className="Steps showgrid">
      <Row>
        <div className="Content">
          {pages.map((page, index) => (
            <div
              style={{
                flex: 1,
                position: 'relative'
              }}
              key={page.id}
              className={`col- ${activePageIndex === index ? 'active' : ''} ${
                activePageIndex > index ? 'visited' : ''
              } text-center`}
            >
              <li>
                <Button
                  id={`navigate-to-step-btn-${index + 1}`}
                  aria-hidden
                  onClick={() => navigationClick(activePageIndex, index)}
                  className={
                    isStepClickable(activePageIndex, index)
                      ? ''
                      : 'not-clickable'
                  }
                >
                  {index + 1}
                </Button>
              </li>
              <span className="desktop">{page.name}</span>
            </div>
          ))}
        </div>
      </Row>
      <Row className="mobile">
        <Col xs={12} className="text-center active">
          <span>{pages[activePageIndex].name}</span>
        </Col>
      </Row>
    </Container>
  );
};

Steps.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  activePageId: PropTypes.any,
  navigationClick: PropTypes.func.isRequired,
  isStepClickable: PropTypes.func
};

Steps.defaultProps = {
  activePageId: null,
  isStepClickable: () => true
};
