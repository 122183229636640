import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import {
  setPayrollPillsData,
  DownloadType,
  DATE
} from '@epi-constants/downloadReports';
import { FormFields } from '@epi-forms/helpers';
import { getPayroll } from '@epi-pages/DownloadReports/helpers';

import { ColumnSection } from '../ColumnSection';
import { InfoBar } from '../InfoBar';
import { BenefitReportsGroup } from '../BenefitReportsGroup';
import { DownloadAs } from '../DownloadAs';
import { TimePeriod } from '../TimePeriod';

export const LunchDeduction = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  const pillsData =
    getValues(FormFields.Payroll).columns || setPayrollPillsData(t);

  return (
    <>
      <InfoBar
        text={t('download_reports.lunch_deduction.info_bar_text')}
        href={t('download_reports.lunch_deduction.info_bar_link')}
      />
      <BenefitReportsGroup />
      <ColumnSection pillsData={pillsData} />
      <TimePeriod
        getFieldName={getPayroll}
        type={DownloadType.payroll}
        disabledCustomDates={{ before: DATE }}
      />
      <DownloadAs name={getPayroll(FormFields.DownloadAs)} />
    </>
  );
};
