import React from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonComponent as Button, ButtonMode } from 'edenred-ui';

import { IBenefitTypeTile } from '@epi-models/containers/ManageBenefits';

import BenefitTypeIcon from '../BenefitTypeIcon/BenefitTypeIcon';

import * as Style from './BenefitTypeTile.styles';

function BenefitTypeTile({
  benefit,
  active = false,
  disabled = false
}: IBenefitTypeTile) {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const benefitName = t(`components.manage_benefits.new.benefits.${benefit}`);

  const buttonText = active
    ? t('components.manage_benefits.new.view_groups')
    : t('components.manage_benefits.new.activate');

  const handleClick = () => {
    push(`${pathname}/${benefit}`);
  };

  return (
    <Style.BenefitTypeTileContainer
      active={active}
      disabled={disabled}
      id={`benefit-tile_${benefitName}`}
    >
      {active && !disabled && (
        <Style.BenefitTypeStatus id={`status-active-${benefit}`}>
          <Style.BenefitTypeStatusText>
            {t('components.manage_benefits.new.active')}
          </Style.BenefitTypeStatusText>
        </Style.BenefitTypeStatus>
      )}
      <BenefitTypeIcon benefit={benefit} disabled={disabled} />
      <Style.BenefitTypeName disabled={disabled}>
        {benefitName}
      </Style.BenefitTypeName>
      {disabled ? (
        <Style.ComingSoonContainer>
          {t('components.manage_benefits.new.coming_soon')}
        </Style.ComingSoonContainer>
      ) : (
        <Style.ViewGroupsButtonContainer>
          <Button
            fullWidth
            id={`view-groups-${benefit}`}
            onClick={handleClick}
            mode={ButtonMode.Primary}
          >
            {buttonText}
          </Button>
        </Style.ViewGroupsButtonContainer>
      )}
    </Style.BenefitTypeTileContainer>
  );
}

export default withRouter(BenefitTypeTile);
