import React from 'react';
import { Box, InfoIcon, CloseIcon, SimpleButton } from 'edenred-ui';

import { INotificationInfo } from '@epi-models/shared';

export const NotificationInfo = ({ onClose, text, id }: INotificationInfo) => (
  <Box
    boxSizing="border-box"
    boxShadow="0 4px 16px -4px rgba(0, 0, 0, 0.2)"
    pt={2}
    pb={2}
    display="flex"
    alignItems="center"
    id={id}
  >
    <Box display="flex" ml={3} mr={3} alignItems="center" width="100%">
      <Box mr={3}>
        <InfoIcon />
      </Box>
      <Box fontSize={16}>{text}</Box>
      <Box ml="auto">
        {onClose && (
          <SimpleButton
            noPadding
            icon={<CloseIcon />}
            onClick={onClose}
            id="transfer-money-notification-button-close"
          />
        )}
      </Box>
    </Box>
  </Box>
);
