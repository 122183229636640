import React from 'react';
import { useTranslation } from 'react-i18next';
import { PagePanel, Tabs } from 'edenred-ui';

import { ReportTypeVirike } from '@epi-constants/externalReport';
import { TopupReports } from '@epi-pages/Reports/TopupReports';
import { TransactionsHistory } from '@epi-containers/TransactionsHistory/TransactionsHistory';
import config from '@epi-config';

import { useExternalReportsTabs } from './hooks/useExternalReportsTabs';

const { enableVirikeBenefit } = config;

const ExternalReportsTabs = () => {
  const { t } = useTranslation();
  const { handleOnTabChange, defaultTabName } = useExternalReportsTabs();
  const tabTransactions = {
    name: ReportTypeVirike.transactions,
    label: t('containers.reports.tabs.transactions'),
    id: 'tab-transactions',
    body: <TransactionsHistory />
  };

  const topUpLoads = {
    name: ReportTypeVirike.topups,
    label: t('containers.reports.tabs.topup'),
    id: 'tab-topup',
    body: <TopupReports />
  };

  const tabs = enableVirikeBenefit
    ? [tabTransactions, topUpLoads]
    : [tabTransactions];

  return (
    <PagePanel title={t('containers.reports.title')}>
      <Tabs
        tabs={tabs}
        onTabChange={handleOnTabChange}
        defaultTabName={defaultTabName}
      />
    </PagePanel>
  );
};

export default ExternalReportsTabs;
