import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DisplayAddress from '../Address/DisplayAddress';
import Panel from '../Panel';

function DisplayBillingInformation({ address, i18n }) {
  if (!address) {
    return null;
  }
  const translationPrefix = 'containers.card_order.summary_and_payment.';

  return (
    <Panel
      title={i18n.t(`${translationPrefix}billing_information_header`)}
      subtitle={i18n.t(`${translationPrefix}billing_information_subheader`)}
    >
      <div className="content">
        <div className="pl-60 row">
          <div className="col-sm-12 col-xs-10">
            <DisplayAddress address={address} />
          </div>
        </div>
      </div>
    </Panel>
  );
}

DisplayBillingInformation.propTypes = {
  address: PropTypes.object,
  i18n: PropTypes.any.isRequired
};

export default withTranslation()(DisplayBillingInformation);
