import Url from 'url';
import { getApplicationLanguageCode } from '@epi-selectors/application';
import * as ErrorDataHelper from '@epi-helpers/ErrorDataHelper';
import { getLoginState } from '@epi-selectors/login';
import { UnauthorizedError } from './UnauthorizedError';
import { ForbiddenError } from './ForbiddenError';
import { ApiError } from './ApiError';
import { reduxStore } from '../store-configuration';

export function createHeaders(extraHeaders) {
  const state = reduxStore.getState();
  const languageCode = getApplicationLanguageCode(state);
  const loginState = getLoginState(state);

  const headers = {
    ...extraHeaders,
    Accept: '*/*',
    'Content-Type': 'application/json',
    'Accept-Language': languageCode
  };

  if (loginState?.accessToken) {
    return {
      ...headers,
      Authorization: `${loginState.tokenType} ${loginState.accessToken}`
    };
  }

  return headers;
}

function handleErrorResponse(response, status, body, json) {
  if (status === 401) {
    return Promise.reject(new UnauthorizedError(`messages.unauthorized_user`));
  }

  if (status === 422) {
    return Promise.reject(new ForbiddenError(`messages.incorrect_password`));
  }

  const errorMessage = `Failed status ${status} (${response.statusText}) on request ${response.url}.`;
  if (body) {
    return Promise.reject(new ApiError(errorMessage, json));
  }

  return Promise.reject(
    new Error(errorMessage, ErrorDataHelper.createErrorResponse([errorMessage]))
  );
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function parseBody(responseBody) {
  return responseBody ? JSON.parse(responseBody) : {};
}

function fetchRetry(url, options, n = 3, sleepMs = 2000) {
  const checkResultFunc = response => {
    if (response.ok || n <= 1) {
      return Promise.resolve([response, null, null]);
    }
    return response.text().then(responseBody => {
      const jsonResult = parseBody(responseBody);
      if (jsonResult.canRetry) {
        return sleep(sleepMs).then(fetchRetry(url, options, n - 1, sleepMs));
      }
      return Promise.resolve([response, response.status, responseBody]);
    });
  };
  return fetch(url, options).then(checkResultFunc);
}

export function checkResponse([response, resolvedStatus, resolvedBody]) {
  return Promise.all([
    resolvedStatus || response.status,
    response.bodyUsed ? resolvedBody : response.text()
  ]).then(([status, body]) => {
    const json = parseBody(body);
    if (response.ok) {
      return Promise.resolve(json);
    }

    return handleErrorResponse(response, status, body, json);
  });
}

async function request(url, method, json, headers, abortController) {
  return fetchRetry(url, {
    method,
    headers: createHeaders(headers),
    body: JSON.stringify(json),
    signal: abortController
  }).then(checkResponse);
}

export function post(url, json, headers, abortController) {
  return request(url, 'POST', json, createHeaders(headers), abortController);
}

export function get(url, options, headers, abortController) {
  const u = Url.parse(url);
  u.query = options;

  return request(
    u.format(),
    'GET',
    undefined,
    createHeaders(headers),
    abortController
  );
}

export function put(url, json, headers, abortController) {
  return request(url, 'PUT', json, createHeaders(headers), abortController);
}

export function patch(url, json, headers, abortController) {
  return request(url, 'PATCH', json, createHeaders(headers), abortController);
}

export function del(url, json, headers, abortController) {
  return request(url, 'DELETE', json, createHeaders(headers), abortController);
}

export const fetchPost = (
  url,
  data,
  onSuccess,
  onFailure,
  extraHeaders = {}
) => {
  return new Promise((resolve, reject) => {
    const abortController = new AbortController();
    post(url, data, extraHeaders, abortController.signal)
      .then(
        res => {
          if (onSuccess) {
            return onSuccess(res);
          }
          resolve('success');
        },
        err => {
          if (onFailure) {
            onFailure(err);
          }
          reject(err);
        }
      )
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        abortController.abort();
      });
  });
};

export const fetchPut = (
  url,
  data,
  onSuccess = null,
  onFailure = null,
  extraHeaders = {}
) => {
  return new Promise((resolve, reject) => {
    const abortController = new AbortController();
    put(url, data, extraHeaders, abortController.signal)
      .then(
        res => {
          if (onSuccess) {
            onSuccess(res);
          }
          resolve('success');
        },
        err => {
          if (onFailure) {
            onFailure(err);
          }
          reject(err);
        }
      )
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        abortController.abort();
      });
  });
};

export const fetchGet = (
  url,
  options = {},
  onSuccess,
  onFailure,
  extraHeaders = {}
) => {
  return new Promise((resolve, reject) => {
    const abortController = new AbortController();
    get(url, options, extraHeaders, abortController.signal)
      .then(
        res => {
          if (onSuccess) {
            onSuccess(res);
          }
          resolve('success');
        },
        err => {
          if (onFailure) {
            onFailure(err);
          }
          reject(err);
        }
      )
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        abortController.abort();
      });
  });
};

export const fetchDelete = (
  url,
  data,
  onSuccess = null,
  onFailure = null,
  extraHeaders = {}
) => {
  return new Promise((resolve, reject) => {
    const abortController = new AbortController();
    del(url, data, extraHeaders, abortController.signal)
      .then(
        res => {
          if (onSuccess) {
            onSuccess(res);
          }
          resolve('success');
        },
        err => {
          if (onFailure) {
            onFailure(err);
          }
          reject(err);
        }
      )
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        abortController.abort();
      });
  });
};

export default {
  checkResponse,
  post,
  get,
  put,
  patch,
  del,
  createHeaders
};
