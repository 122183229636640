import React from 'react';
import { useTheme } from 'styled-components';
import { CloseIcon, Pill, PillType, PlusIcon, Box } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { getOther } from '@epi-pages/DownloadReports/helpers/getOther';
import { FormFields } from '@epi-forms/helpers';
import { dataScopePills } from '@epi-constants/downloadReports';

export const DataScopeSection = () => {
  const { setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const { primaryFontColor, sizeMedium, fontSemiBold, gray25 } = useTheme();
  const dataScope = watch(getOther(FormFields.DataScope));

  const handleAction = (group: string) => {
    setValue(getOther(FormFields.DataScope), {
      ...dataScope,
      [group]: !dataScope[group]
    });
  };

  return (
    <Box
      fontWeight={fontSemiBold}
      fontSize={sizeMedium}
      color={primaryFontColor}
      pt={6}
      pb={5}
      borderBottom={1}
      borderColor={gray25}
    >
      <Box>{t('containers.reports.data_scope.title')}</Box>
      <Box display="flex" flexWrap="wrap" flexDirection="row" pt={2} gap={1}>
        {dataScopePills.map(p => {
          return (
            <Pill
              key={p.group}
              id={`data-scope-${p.group}`}
              type={PillType.Large}
              label={t(`containers.reports.data_scope.${p.group}`)}
              isActive={dataScope[p.group]}
              action={() => handleAction(p.group)}
              icon={dataScope[p.group] ? <CloseIcon /> : <PlusIcon />}
            />
          );
        })}
      </Box>
    </Box>
  );
};
