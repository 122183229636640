import React from 'react';
import { useTranslation } from 'react-i18next';

import { BenefitsTabName } from '@epi-constants/beneficiary';

import { Benefits } from '../../BeneficiaryDetail/components';
import { AddPersonalData } from '../components';

export const useAddBeneficiaryTab = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      name: BenefitsTabName.personalData,
      label: t('containers.beneficiaries.detail.personal_data'),
      id: 'add-tab-personal-data',
      body: <AddPersonalData />
    },
    {
      name: BenefitsTabName.benefits,
      label: t('containers.beneficiaries.detail.benefits'),
      id: 'add-tab-benefits',
      body: <Benefits isAddMode />
    }
  ];

  return {
    tabs
  };
};
