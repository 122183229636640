import { useEffect, useRef, useState } from 'react';

import { ITerminationDatePicker } from '@epi-models/pages/Beneficiaries';

export const useTerminationDatePicker = ({
  dayValue,
  setTerminationDate
}: ITerminationDatePicker) => {
  const ref = useRef<any>();
  const [day, setDay] = useState<null | Date>(null);

  const handleOnDayClick = () => ref.current.input.focus();

  const handleChange = (value: Date | null, modifi, pickerInput) => {
    if (!pickerInput.input.value) {
      return setTerminationDate(null);
    }
    setDay(value);
    setTerminationDate(value);
  };

  useEffect(() => {
    if (dayValue) {
      setDay(dayValue);
    }
  }, []);

  useEffect(() => {
    if (ref?.current) {
      ref.current.input.focus();
    }
  }, []);

  return {
    day,
    ref,
    handleChange,
    handleOnDayClick
  };
};
