import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/* See https://reacttraining.com/react-router/web/guides/scroll-restoration */
const ScrollToTop = ({ children, pathname }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const container = document.getElementsByClassName('sidebar-content');
    [].forEach.call(container, elt => elt.scrollTo && elt.scrollTo(0, 0));
  }, [pathname]);

  return children;
};

ScrollToTop.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  location: PropTypes.object.isRequired
};
ScrollToTop.defaultProps = {
  children: null
};

const ScrollToTopWithRouter = withRouter(ScrollToTop);
export { ScrollToTopWithRouter as ScrollToTop };
