import React from 'react';
import { Box, InfoIcon } from 'edenred-ui';
import { Trans } from 'react-i18next';
import { IInfoBar } from '@epi-models/pages/DownloadReports';

export const InfoBar = ({ text, href }: IInfoBar) => (
  <Box
    mt={3}
    boxSizing="border-box"
    boxShadow="0 4px 16px -4px rgba(0, 0, 0, 0.2)"
    pt={2}
    pb={2}
    display="flex"
    alignItems="center"
  >
    <Box display="flex" ml={3} mr={3} alignItems="center" width="100%">
      <Box mr={3}>
        <InfoIcon />
      </Box>
      <Box width={1080} fontSize={16}>
        {href ? (
          <Trans
            defaults={text}
            components={{
              a: <a target="_blank" rel="noopener noreferrer" href={href} />
            }}
          />
        ) : (
          <div>{text}</div>
        )}
      </Box>
    </Box>
  </Box>
);
