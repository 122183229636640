import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'edenred-ui';

import { DownloadType, TODAY } from '@epi-constants/downloadReports';
import { useReportsTab } from '@epi-pages/DownloadReports/hooks';
import { getOther } from '@epi-pages/DownloadReports/helpers/getOther';
import { FormFields } from '@epi-forms/helpers';

import { DataColumnsSection } from '../DataColumnsSection';
import { InfoBar } from '../InfoBar';
import { TimePeriod } from '../TimePeriod';
import { DownloadAs } from '../DownloadAs';
import { DataScopeSection } from '../DataScopeSection';

export const ReportsTab = () => {
  const { t } = useTranslation();
  const { displayColumnPills } = useReportsTab();

  return (
    <Box pb={10}>
      <InfoBar text={t('containers.reports.info_bar')} />
      <DataScopeSection />
      {displayColumnPills && <DataColumnsSection />}
      <TimePeriod
        getFieldName={getOther}
        type={DownloadType.other}
        disabledCustomDates={{ after: TODAY }}
      />
      <DownloadAs name={getOther(FormFields.DownloadAs)} />
    </Box>
  );
};
