import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { showNotification } from '@epi-actions/overlays';
import { fetchGet } from '@epi-helpers/FetchHelpers';
import { companyBenefitGroupSettingsActiveBenefitsUrl } from '@epi-repositories/BenefitGroupSettingsRepository';
import { genericErrorMessage } from '@epi-constants/downloadReports';
import { FormFields } from '@epi-forms/helpers';

import { getOther, setActiveBenefits } from '../helpers';

export const useReportsTab = () => {
  const dispatch = useDispatch();
  const { watch, setValue } = useFormContext();

  const dataScope = watch(getOther(FormFields.DataScope));

  useEffect(() => {
    const onSuccess = res => {
      setValue(getOther(FormFields.ActiveBenefits), setActiveBenefits(res));
    };
    const onFailure = () =>
      dispatch(showNotification({ message: genericErrorMessage }, 'error'));
    fetchGet(
      companyBenefitGroupSettingsActiveBenefitsUrl(),
      {},
      onSuccess,
      onFailure
    );
  }, []);

  const displayColumnPills =
    dataScope.employeeData || dataScope.benefits || dataScope.loads;

  return { displayColumnPills };
};
