import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, OutlineWarningIcon, ButtonMode, PagePanel } from 'edenred-ui';
import { useDispatch } from 'react-redux';

import { EmptyState, TransferPageHeader } from '@epi-shared/components';
import { useMenuDimensions } from '@epi-shared/hooks';
import { DownloadInvoiceButton } from '@epi-components/DownloadInvoiceButton/DownloadInvoiceButton';
import { successDepositOrderAfterPDFInvoice } from '@epi-actions/api';
import { IPDFInvoiceSuccess } from '@epi-models/containers/OrderStatus';

export const PDFInvoiceSuccess = ({ paymentId }: IPDFInvoiceSuccess) => {
  const { t } = useTranslation();
  const { isMobile } = useMenuDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(successDepositOrderAfterPDFInvoice());
  }, []);

  const description = (
    <Box width={600} textAlign="center" mt={2}>
      <Trans
        defaults={t('containers.deposit_money.pdf_invoice.success.sub_title')}
        components={{ break: <br /> }}
      />
    </Box>
  );

  return (
    <PagePanel
      title={t('containers.deposit_money.header')}
      headerContent={!isMobile && <TransferPageHeader />}
    >
      <EmptyState
        icon={<OutlineWarningIcon />}
        title={t('containers.deposit_money.pdf_invoice.success.title')}
        description={description}
      >
        <Box mt={4}>
          <DownloadInvoiceButton
            idType="payment"
            id={paymentId?.toString() ?? 'download-invoice-button'}
            mode={ButtonMode.Primary}
          >
            {t('containers.deposit_money.pdf_invoice.button')}
          </DownloadInvoiceButton>
        </Box>
      </EmptyState>
    </PagePanel>
  );
};
