import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { PagePanel } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { BenefitType } from '@epi-constants/benefitTypes';
import { getActiveBenefits } from '@epi-actions/api';
import {
  selectActiveBenefits,
  selectIsActiveBenefitsLoaded
} from '@epi-selectors/benefitGroupSettings';
import { isSupportedBenefit } from '@epi-helpers/benefitsHelper';
import { IManageBenefits } from '@epi-models/containers/ManageBenefits';

import * as Style from './ManageBenefits.styles';
import BenefitTypeTile from './components/BenefitTypeTile/BenefitTypeTile';

function ManageBenefits({
  activeBenefits = [],
  isActiveBenefitsLoaded
}: IManageBenefits) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getActiveBenefits());
  }, []);

  const benefitTiles =
    isActiveBenefitsLoaded &&
    Object.values(BenefitType).map(type => (
      <BenefitTypeTile
        key={type}
        benefit={type}
        active={activeBenefits
          .map(activeBenefit => activeBenefit.toLowerCase())
          .includes(type as string)}
        disabled={!isSupportedBenefit(type)}
      />
    ));

  return (
    <PagePanel title={t('components.manage_benefits.new.title')}>
      <Style.ManageBenefitsTiles>{benefitTiles}</Style.ManageBenefitsTiles>
    </PagePanel>
  );
}

const connectToStore = connect(state => ({
  activeBenefits: selectActiveBenefits(state),
  isActiveBenefitsLoaded: selectIsActiveBenefitsLoaded(state)
}));

export default connectToStore(ManageBenefits);
