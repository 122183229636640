import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  PanelComponent,
  ButtonMode,
  Notification,
  NotificationType
} from 'edenred-ui';

import { generateUrlWithLocale } from '@epi-selectors/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { INotFound } from '@epi-models/containers/NotFound';

import { NotFoundPanelContent, StyledButton } from './NotFound.styles';

const NotFound = ({ generateUrl }: INotFound) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row>
        <Col xs={12} lg={10}>
          <PanelComponent>
            <Notification
              type={NotificationType.Error}
              text={t('containers.not_found.title')}
            />
            <NotFoundPanelContent>
              <StyledButton id="login_redirect">
                <a href={generateUrl(routerPaths.login)}>
                  {t('containers.not_found.login.text')}
                </a>
              </StyledButton>
              <StyledButton mode={ButtonMode.Link} id="help_redirect">
                <a href={t('containers.not_found.support_page.link')}>
                  {t('containers.not_found.support_page.text')}
                </a>
              </StyledButton>
            </NotFoundPanelContent>
          </PanelComponent>
        </Col>
      </Row>
    </Container>
  );
};

const ConnectedNotFound = connect(state => ({
  generateUrl: route => generateUrlWithLocale(route)(state)
}))(NotFound);

export { ConnectedNotFound as NotFound };
