import React from 'react';
import { Box } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';

import { FormFields } from '@epi-forms/helpers';
import { maxLength } from '@epi-helpers/formHelpers/validators';
import { TerminationDate } from '@epi-pages/Beneficiaries/components/EndOfBenefit/TerminationDate';
import {
  FirstNameField,
  LastNameField,
  EmailAddressField
} from '@epi-forms/controls';

import { PersonalDataField } from '../PersonalDataField';

export const PersonalData = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const {
    field: { value: isEditMode }
  } = useController({
    name: FormFields.EditMode,
    control
  });

  const {
    field: { value: EoF }
  } = useController({
    name: FormFields.EndOfFunds,
    control
  });

  const showTerminationButton = !EoF || isEditMode;
  const minHeight = showTerminationButton && EoF ? 1000 : 820;

  return (
    <Box
      px={10}
      pt={5}
      gap={3}
      display="flex"
      flexDirection="column"
      minHeight={minHeight}
      id="employee-benefit-personal-data-tab"
    >
      <FirstNameField readOnly={!isEditMode} />
      <LastNameField readOnly={!isEditMode} />
      {/* TODO add in epic EF-8986 */}
      {/* <Box fontSize={12} fontWeight={400}>
        <EmployeeTypeDataField />
        {t('containers.beneficiaries.detail.employee_type_subtext')}
      </Box> */}
      <PersonalDataField
        label={t('containers.beneficiaries.detail.personal_id')}
        name={FormFields.PersonalID}
        disabled
      />
      <PersonalDataField
        label={t('containers.beneficiaries.detail.employee_number')}
        name={FormFields.EmployeeNumber}
        validate={[maxLength(20)]}
      />
      <PersonalDataField
        label={t('containers.beneficiaries.detail.cost_center_number')}
        name={FormFields.CostCenterNumber}
        validate={[maxLength(50)]}
      />
      <EmailAddressField
        // @ts-ignore
        label={t('containers.beneficiaries.table.email')}
        readOnly={!isEditMode}
      />
      <PersonalDataField
        label={t('containers.beneficiaries.detail.create_data')}
        name={FormFields.CreatedAt}
        disabled
      />
      <TerminationDate showTerminationButton={showTerminationButton} />
    </Box>
  );
};
