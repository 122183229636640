import React from 'react';
import { FormProvider } from 'react-hook-form';

import { DownloadReportsTab } from './DownloadReportsTab';
import { useDownloadReportsForm } from './hooks';

export const DownloadReports = () => {
  const { form } = useDownloadReportsForm();

  return (
    <FormProvider {...form}>
      <DownloadReportsTab />
    </FormProvider>
  );
};
