export const getBeneficiaryPayload = values => {
  const {
    personId,
    personalId,
    firstName,
    lastName,
    emailAddress,
    endOfBenefit,
    EmployeeType,
    employeeNumber,
    costCenter
  } = values;
  return {
    personId,
    personalId,
    firstName,
    lastName,
    emailAddress,
    endOfBenefit,
    EmployeeType,
    employeeNumber,
    costCenter
  };
};
