import { NotificationType } from 'edenred-ui';
import { ReactElement, ReactNode, useState } from 'react';

export const useToast = () => {
  const [content, setContent] = useState<ReactNode | undefined>();
  const [button, setButton] = useState<ReactElement | undefined>();
  const [type, setType] = useState<NotificationType | undefined>();
  const [hasCloseBtn, setHasCloseBtn] = useState(false);

  const showToast = (
    type: NotificationType,
    content: ReactNode,
    button?: ReactElement,
    hasCloseBtn?: boolean
  ) => {
    setContent(content);
    setButton(button);
    setType(type);
    if (typeof hasCloseBtn === 'boolean') setHasCloseBtn(hasCloseBtn);
  };

  const closeToast = () => {
    setContent(undefined);
    setButton(undefined);
    setType(undefined);
  };

  return {
    showToast,
    closeToast,
    type,
    content,
    button,
    hasCloseBtn
  };
};
