import React from 'react';
import { Box, TitleText, Checkbox, FormControlLabel } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { FormFields } from '@epi-forms/helpers';
import { getOther } from '@epi-pages/DownloadReports/helpers/getOther';

const name = getOther(FormFields.ActiveBenefits);

export const BenefitTypeCheckboxes = () => {
  const { sizeMedium, fontSemiBold, gray85 } = useTheme();
  const { control } = useFormContext();
  const { t } = useTranslation();

  const {
    field: { value: activeBenefits, onChange }
  } = useController({
    name,
    control
  });

  const handleOnChange = (e, checked) => {
    const newActiveBenefits = activeBenefits.map(item =>
      item.name === e.target.name ? { ...item, checked } : item
    );
    onChange(newActiveBenefits);
  };

  return activeBenefits.length > 0 ? (
    <Box display="flex" alignItems="center" gap={12} pb={5}>
      <TitleText
        fontSize={sizeMedium}
        fontWeight={fontSemiBold}
        color={gray85}
        noMargin
      >
        {t('containers.reports.benefit_type.title')}
      </TitleText>
      <Box fontSize={16} fontWeight={400} position="relative" top={3}>
        <Box display="flex" gap={2.5}>
          {activeBenefits.map(benefit => (
            <FormControlLabel
              key={benefit.name}
              label={t(
                `containers.reports.benefit_type.${benefit.name.toLowerCase()}`
              )}
              control={
                <Checkbox
                  name={benefit.name}
                  checked={benefit.checked}
                  id={`data-columns-checkbox-${benefit.name}`}
                  onChange={handleOnChange}
                />
              }
            />
          ))}
        </Box>
      </Box>
    </Box>
  ) : null;
};
