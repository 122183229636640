import tokeys from 'tokeys';

export const NavigationConstants = tokeys([
  'REPLACE_PAGE',
  'GO_TO_PAGE',
  'GO_TO_LOGIN_PAGE',
  'GO_TO_SIGN_UP_PAGE',
  'ADD_LOCATION_HISTORY',
  'SAVE_LOCATION_HISTORY',
  'DELETE_LAST_LOCATION_HISTORY',
  'GO_RETURN_URL',
  'NEW_ORDER',
  'OPEN_PAGE_EVENT_START',
  'OPEN_PAGE_EVENT_END',
  'CHANGE_NAVIGATION_CONTEXT'
]);

export const ProfileConstants = tokeys([
  'CHANGE_SELECTED_PROFILE_TAB',
  'CLEAR_PROFILE'
]);

export const CompanyInfoConstants = tokeys([
  'SET_CONTACTS_ORDER',
  'FILL_COMPANY_FORM',
  'UNBLOCK_COMPANY_FORM',
  'SHOW_CONTACT_SLIDEOUT',
  'HIDE_CONTACT_SLIDEOUT',
  'ENABLE_SLIDEOUT_EDIT_MODE',
  'DISABLE_SLIDEOUT_EDIT_MODE',
  'ENABLE_EDIT_MODE',
  'DISABLE_EDIT_MODE'
]);

export const StaticFilesConstants = tokeys(['LOAD_MAINTENANCE_CONTENT']);

export const ApplicationConstants = tokeys([
  'CHANGE_APP_LANGUAGE',
  'COPY_VALUE_TO_FIELD',
  'CHECK_USERNAME_ON_MOUNT',
  'CLEAN_COMPANY_EXISTS_INFO',
  'REMEMBER_ME',
  'CLEAR_FORMS',
  'SET_GUID'
]);

export const OverlaysConstants = tokeys([
  'SHOW_NOTIFICATION',
  'SHOW_ERROR_NOTIFICATION',
  'HIDE_NOTIFICATION',
  'REMOVE_ALL_NOTIFICATIONS',
  'SHOW_ERROR_POPUP',
  'SHOW_ERROR_POPUP',
  'CLOSE_COMPANY_EXISTS_MODAL',
  'SET_PASSWORD_POPUP_VISIBILITY',
  'SET_PASSWORD_POPUP_LOADING_STATE',
  'SET_ERROR_MESSAGE_ON_PASSWORD_POPUP',
  'SET_ERROR_MESSAGE_OFF_PASSWORD_POPUP',
  'PASSWORD_POPUP_ACTION',
  'SET_LOGIN_POPUP_VISIBILITY',
  'SHOW_LOGIN_POPUP',
  'LOGIN_POPUP_ACTION',
  'CHANGE_LOGIN_POPUP_CONTENT'
]);

export const VoucherReorderConstants = tokeys([
  'SET_REORDER_VOUCHER_AND_AMOUNT',
  'LOAD_REORDER_DATA'
]);

export const ApiConstants = tokeys([
  'LOAD_PAYMENT_METHODS',
  'LOAD_ORDER_FEES',
  'LOAD_VOUCHERS',
  'LOAD_COUNTRIES',
  'CREATE_VOUCHER_ORDER',
  'CREATE_NEW_PAYMENT',
  'GET_PAYMENT_STATUS',
  'SIGN_UP',
  'CHECK_IF_COMPANY_EXISTS',
  'LOGIN_ACTION',
  'LOGOUT_ACTION',
  'CHANGE_PASSWORD',
  'UPDATE_USER_INFORMATION',
  'GET_USER_INFORMATION',
  'LOAD_VOUCHER_ORDERS_HISTORY',
  'LOAD_VOUCHER_ORDER_DETAILS',
  'CHECK_USERNAME_AVAILABILITY',
  'RUN_USERNAME_ASYNC_VALIDATION',
  'LOAD_USER_COMPANY',
  'UPDATE_USER_COMPANY',
  'SAVE_API_ERROR',
  'CLEAR_API_ERROR',
  'LOAD_ADDRESSES',
  'CLEAR_SAVED_HISTORY',
  'LOAD_VOUCHER_ORDER_BY_PAYMENT',
  'ADD_NEW_PAYMENT_TO_VOUCHER',
  'ADD_NEW_PAYMENT_TO_CARD',
  'ADD_NEW_PAYMENT_TO_DEPOSIT',
  'ADD_NEW_CONTACT',
  'DOWNLOAD_INVOICE',
  'RESET_PASSWORD',
  'SET_NEW_PASSWORD',
  'CHECK_RESET_PASSWORD_TOKEN',
  'CREATE_VOUCHER_REORDER',
  'LOAD_CONTACTS',
  'LOAD_SETTINGS',
  'LOAD_CARD_FEES',
  'LOAD_COMPANY_ACCOUNTS',
  'LOAD_COMPANY_METADATA',
  'LOAD_TRANSACTIONS_HISTORY',
  'ADD_SIGNATORY_ROLE_TO_USER',
  'DEPOSIT_COMPANY_ACCOUNT',
  'GET_RECEIPT_FOR_DEPOSIT',
  'GET_RECEIPT_FOR_TRANSACTION',
  'REFRESH_TOKEN',
  'LOAD_COMPANY_HAS_SIGNATORY',
  'BENEFIT_GROUP_SETTINGS',
  'TRANSFER_MONEY_ESTIMATION',
  'GET_HAS_ORDERS',
  'GET_ACTIVE_BENEFITS',
  'GET_BENEFIT_GROUPS',
  'UPDATE_BENEFIT_GROUP',
  'CREATE_BENEFIT_GROUP',
  'GET_FEE'
]);

export const SagaConstants = tokeys([
  'SAGA_ENSURE_LOADED',
  'SAGA_DATA_LOADED',
  'RESET_DATA_LOADED',
  'START_LOADING',
  'END_LOADING'
]);

export const OrderHistoryConstants = tokeys([
  'RESET_VOUCHER_ORDERS_HISTORY_QUERY_PARAMETERS',
  'SET_VOUCHER_ORDERS_HISTORY_QUERY_PARAMETERS',
  'UPDATE_VOUCHER_ORDERS_HISTORY_QUERY_PARAMETERS'
]);

export const BeneficiaryConstants = tokeys([
  'UPDATE_BENEFICIARIES_QUERY_PARAMETERS',
  'INITIALIZE_BENEFIT_SETTINGS_CONTAINER'
]);

export const AddressPickerConstants = tokeys([
  'SET_DEFAULT_DELIVERY_ADDRESS',
  'SET_DELIVERY_ADDRESS',
  'RESET_DELIVERY_ADDRESS'
]);

export const ReduxFormConstants = tokeys([
  'UNTOUCH_ALL',
  'STACK_FIELD_ARRAY_VALUE',
  'REMOVE_FROM_FIELD_ARRAY'
]);

export const TransactionsHistoryConstants = tokeys([
  'RESET_TRANSACTIONS_HISTORY_QUERY_PARAMETERS',
  'SET_TRANSACTIONS_HISTORY_QUERY_PARAMETERS',
  'UPDATE_TRANSACTIONS_HISTORY_QUERY_PARAMETERS'
]);

export const PaymentsConstants = tokeys([
  'RESET_PAYMENT_METHODS_QUERY_PARAMETERS'
]);
