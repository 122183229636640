import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BackIcon } from 'edenred-ui';

import history from '../../routes/history';

import './BackButton.scss';

export const BackButton = ({ onClick }) => (
  <Button
    id="back-btn"
    variant="link"
    className="BackButton btn-back p-0 mr-3"
    onClick={onClick}
  >
    <BackIcon />
  </Button>
);

BackButton.propTypes = {
  onClick: PropTypes.func
};

BackButton.defaultProps = {
  onClick: () => history.goBack()
};
