import React from 'react';
import { withTheme, useTheme } from 'styled-components';

import { BenefitType } from '@epi-constants/benefitTypes';
import { CommutingIcon } from '@epi-components/Icons/CommutingIcon';
import { LunchIcon } from '@epi-components/Icons/LunchIcon';
import { MassageIcon } from '@epi-components/Icons/MassageIcon';
import { RecreationalIcon } from '@epi-components/Icons/RecreationalIcon';
import { IBenefitTypeIcon } from '@epi-models/containers/ManageBenefits';

function BenefitTypeIcon({ benefit, disabled }: IBenefitTypeIcon) {
  const { altGray1, primaryBackColorLight } = useTheme();
  const iconFill = disabled ? altGray1 : primaryBackColorLight;

  switch (benefit) {
    case BenefitType.Lunch:
      return <LunchIcon fill={iconFill} />;
    case BenefitType.Recreational:
      return <RecreationalIcon fill={iconFill} />;
    case BenefitType.Transport:
      return <CommutingIcon fill={iconFill} />;
    case BenefitType.Massage:
      return <MassageIcon fill={iconFill} />;
    default:
      return null;
  }
}

export default withTheme(BenefitTypeIcon);
