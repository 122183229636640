import { setColumnInitialValue } from '@epi-pages/DownloadReports/helpers';
import { TFunction } from 'react-i18next';

export const WAGE_TYPE = 'wageType';
export const DATE = new Date('September 1, 2024');
export const TODAY = new Date();

export const genericErrorMessage = 'messages.error';
export const reportDownloadSuccessMessage =
  'containers.reports.download_success';

export const Benefit = {
  Lounari: 'Lunch',
  Virike: 'Virike'
};

export const DownloadType = {
  payroll: 'payroll',
  other: 'other'
};

export const dataScopePills = [
  { group: 'employeeData', label: 'Employee data' },
  { group: 'benefits', label: 'Benefits' },
  { group: 'loads', label: 'Loads' }
];

export const TabName = {
  Lunch: 'Lunch',
  Other: 'Other'
};

export const Download = {
  CSV: 'csv',
  XLS: 'xls'
};

export const dateRangeModes = {
  datePicker: 'datePicker',
  currentMonth: 'currentMonth',
  lastMonth: 'lastMonth'
};

export const requiredTypeForDownloadReport = ['firstName', 'lastName', 'SSN'];

export const setPayrollPillsData = (t: TFunction<'translation', undefined>) => {
  return [
    {
      id: 0,
      name: t('download_reports.lunch_deduction.list.personal_id'),
      type: 'SSN',
      isActive: true
    },
    {
      id: 1,
      name: t('download_reports.lunch_deduction.list.first_name'),
      type: 'firstName',
      isActive: true
    },
    {
      id: 2,
      name: t('download_reports.lunch_deduction.list.last_name'),
      type: 'lastName',
      isActive: true
    },
    {
      id: 3,
      name: t('download_reports.lunch_deduction.list.benefit'),
      type: 'benefitType',
      isActive: true
    },
    {
      id: 4,
      name: t('download_reports.lunch_deduction.list.sum_of_loads'),
      type: 'sumOfLoads',
      isActive: true
    },
    {
      id: 5,
      name: t('download_reports.lunch_deduction.list.payroll_deduction'),
      type: 'payrollDeduction',
      isActive: true
    },
    {
      id: 6,
      name: t('download_reports.lunch_deduction.list.load_month'),
      type: 'loadMonth',
      isActive: true
    },
    {
      id: 7,
      name: t('download_reports.lunch_deduction.list.request_month'),
      type: 'requestMonth',
      isActive: true
    },
    {
      id: 8,
      name: t('download_reports.lunch_deduction.list.daily_amount'),
      type: 'dailyAmount',
      isActive: true
    },
    {
      id: 9,
      name: t('download_reports.lunch_deduction.list.employee_number'),
      type: 'employeeNumber',
      isActive: false
    },
    {
      id: 10,
      name: t('download_reports.lunch_deduction.list.cost_center_number'),
      type: 'costCenter',
      isActive: false
    },
    {
      id: 11,
      name: t('download_reports.lunch_deduction.list.wage_type'),
      type: 'wageType',
      isActive: false
    }
  ];
};

export const setOtherReportsDataPills = (
  t: TFunction<'translation', undefined>
) => {
  return [
    {
      id: 'firstName',
      group: 'employeeData',
      active: setColumnInitialValue('firstName')
    },
    {
      id: 'lastName',
      group: 'employeeData',
      active: setColumnInitialValue('lastName')
    },
    {
      id: 'SSN',
      group: 'employeeData',
      active: setColumnInitialValue('SSN')
    },
    {
      id: 'employeeNumber',
      group: 'employeeData',
      active: setColumnInitialValue('employeeNumber')
    },
    {
      id: 'costCenter',
      group: 'employeeData',
      active: setColumnInitialValue('costCenter')
    },
    {
      id: 'benefitType',
      group: 'benefits',
      active: setColumnInitialValue('benefitType')
    },
    {
      id: 'benefitStart',
      group: 'benefits',
      active: setColumnInitialValue('benefitStart')
    },
    {
      id: 'loadAmount',
      group: 'loads',
      active: setColumnInitialValue('loadAmount')
    },
    {
      id: 'loadDate',
      group: 'loads',
      active: setColumnInitialValue('loadDate')
    },
    {
      id: 'loadStatus',
      group: 'loads',
      active: setColumnInitialValue('loadStatus')
    }
  ];
};
