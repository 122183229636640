export const developEnvVariables = {
  environment: 'development',
  apiHost: 'https://uat-tyonantaja-api.edenred.fi', // QA 'http://fi-cicd.edenred.net:8183'; //  LOCAL 'https://localhost:44378'; //https://uat-tyonantaja-api.edenred.fi //http://fi-cicd.edenred.net:8083
  enableVirikeBenefit: true,
  enablePersistanceOnLocalStorage: true,
  disablePersistanceCompression: true,
  disableReCaptcha: false,
  googleAnalyticsKey: '',
  onetrustKey: '',
  enableNewContracts: true,
  showNotificationBanner: false,
  notificationBannerStartDate: 22,
  notificationBannerEndDate: 27,
  disableEditOptionsForOA: false,
  disableAddingContactPersonOnCompanyInformation: false
};
