import React, { PureComponent } from 'react';
import { Button, ButtonSize } from 'edenred-ui';
import PropTypes from 'prop-types';
import './RoundButton.scss';

export const RoundButton = ({ icon, className, onClick, id }) => {
  return (
    <Button
      type="button"
      id={id}
      size={ButtonSize.Medium}
      variant="info"
      className={`RoundButton ${className}`}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};

RoundButton.propTypes = {
  icon: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string
};
RoundButton.defaultProps = {
  className: '',
  onClick: () => {}
};
