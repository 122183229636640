//@ts-nocheck
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Box } from 'edenred-ui';

import * as transactionsHistoryActions from '@epi-actions/transactionsHistory';
import { Pagination } from '@epi-shared/components';
import { TransactionsHistoryTable } from '@epi-components/TransactionsHistoryTable/TransactionsHistoryTable';
import { getApplicationLanguageCode } from '@epi-selectors/application';
import { transactionsHistory } from '@epi-selectors/loading';

import './TransactionsHistory.scss';

export interface ITransactionsHistory {}

function TransactionsHistory({
  isLoading,
  transactions,
  totalPages,
  queryParameters,
  languageCode,
  setTransactionsHistoryParameters
}) {
  useEffect(() => {
    setTransactionsHistoryParameters(queryParameters);
  }, [languageCode]);

  return (
    <div className="TransactionsHistory">
      <TransactionsHistoryTable
        isLoading={isLoading}
        transactions={transactions}
        sortBy={queryParameters.sortingBy}
        sortDirection={queryParameters.descending}
        onSortingChange={(sortingBy, descending) => {
          setTransactionsHistoryParameters({
            ...queryParameters,
            sortingBy,
            descending
          });
        }}
      />
      <Box pt={3}>
        <Pagination
          count={totalPages}
          page={queryParameters.page}
          onChangePage={page => {
            setTransactionsHistoryParameters({ ...queryParameters, page });
          }}
        />
      </Box>
    </div>
  );
}

TransactionsHistory.propTypes = {
  queryParameters: PropTypes.object.isRequired,
  setTransactionsHistoryParameters: PropTypes.func.isRequired,
  transactions: PropTypes.array.isRequired,
  totalPages: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  languageCode: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...transactionsHistoryActions
    },
    dispatch
  );

const mapStateToProps = state => ({
  totalPages: state.apiTransactionsHistory.totalPages,
  queryParameters: state.apiTransactionsHistory.queryParameters,
  transactions: state.apiTransactionsHistory.transactions,
  languageCode: getApplicationLanguageCode(state),
  isLoading: transactionsHistory(state)
});

const ConnectedTransactionsHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TransactionsHistory));

const ConnectedTransactionsHistoryWithRouter = withRouter(
  ConnectedTransactionsHistory
);
export { ConnectedTransactionsHistoryWithRouter as TransactionsHistory };
