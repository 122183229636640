import React, { useState } from 'react';

export const useTerminationDate = () => {
  const [timePeriodDialog, setTimePeriodDialog] = useState(false);

  const handleClose = () => setTimePeriodDialog(false);
  const handleOpen = () => setTimePeriodDialog(true);
  return {
    timePeriodDialog,
    handleClose,
    handleOpen
  };
};
