import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { ISelectOption } from 'edenred-ui';
import { useSelector } from 'react-redux';

import { apiToDefaultDateFormat } from '@epi-helpers/dateHelper';
import {
  dateRangeModes,
  DATE,
  DownloadType
} from '@epi-constants/downloadReports';
import { setStartDate, setEndDate } from '@epi-helpers/reportsHelper';
import { getApplicationLocale } from '@epi-selectors/application';
import { FormFields } from '@epi-forms/helpers';
import { IUseTimePeriod } from '@epi-models/pages/DownloadReports';

export const useTimePeriod = ({ getFieldName, type }: IUseTimePeriod) => {
  const { t } = useTranslation();
  const { setValue, getFieldState, getValues, clearErrors, setError } =
    useFormContext();

  const locale: string = useSelector(getApplicationLocale);

  const [timePeriodDialog, setTimePeriodDialog] = useState(false);

  const startDate = getValues(getFieldName(FormFields.StartDate));
  const endDate = getValues(getFieldName(FormFields.EndDate));
  const selectedId = getValues(getFieldName(FormFields.SelectedID));
  const isError = getFieldState(getFieldName(FormFields.StartDate)).error;
  const errorMsg = getFieldState(getFieldName(FormFields.StartDate)).error
    ?.message;
  const datePicker =
    startDate && endDate && selectedId === dateRangeModes.datePicker
      ? `${apiToDefaultDateFormat(startDate)} - ${apiToDefaultDateFormat(
          endDate
        )}`
      : t('download_reports.lunch_deduction.time_period.select_dates');

  const options = [
    {
      display: t('download_reports.lunch_deduction.time_period.previous_month'),
      id: dateRangeModes.lastMonth,
      testID: `${type}-report-${dateRangeModes.currentMonth}`
    },
    {
      display: t('download_reports.lunch_deduction.time_period.current_month'),
      id: dateRangeModes.currentMonth,
      testID: `${type}-report-${dateRangeModes.lastMonth}`
    },
    {
      display: datePicker,
      id: dateRangeModes.datePicker,
      testID: `${type}-report-${dateRangeModes.datePicker}`
    }
  ];

  const handleOnChange = (v: ISelectOption) => {
    setValue(getFieldName(FormFields.SelectedID), v.id.toString());
    setValue(getFieldName(FormFields.StartDate), setStartDate(v.id.toString()));
    setValue(getFieldName(FormFields.EndDate), setEndDate(v.id.toString()));
    clearErrors(getFieldName(FormFields.StartDate));

    if (v.id.toString() === dateRangeModes.datePicker) {
      setTimePeriodDialog(true);
    }
  };

  useEffect(() => {
    const from = new Date(startDate);
    const isPayroll =
      type === DownloadType.payroll && from && DATE.getTime() > from.getTime();

    if (isPayroll) {
      setError(getFieldName(FormFields.StartDate), {
        type: 'custom',
        message: t(
          `download_reports.lunch_deduction.time_period.error_message_${type}`
        )
      });
    }
  }, [locale]);

  return {
    options,
    isError,
    errorMsg,
    handleOnChange,
    timePeriodDialog,
    setTimePeriodDialog,
    selectedId
  };
};
