import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {
  PanelComponent,
  Button,
  LoadingSpinner,
  ButtonMode,
  ButtonSize
} from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { Form } from '@epi-components/Form';
import { ResetPasswordField } from '@epi-forms/controls/ResetPasswordField';
import { IResetPassword } from '@epi-models/containers/ResetPassword';

import history from '../../routes/history';
import { isLoading as isLoadingSelector } from '../../selectors/saga';
import { ApiConstants } from '../../constants/actions';
import * as apiActions from '../../actions/api';

import './ResetPassword.scss';

const ResetPasswordWrapper = styled.div`
  padding-top: 10px;
`;

function ResetPassword({
  resetPassword,
  isLoading,
  submitting
}: IResetPassword) {
  const { t } = useTranslation();

  const submitForm = values => {
    resetPassword(values.usernameOrEmail);
  };

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      usernameOrEmail: ''
    }
  });

  const {
    formState: { isValid }
  } = form;

  return (
    <ResetPasswordWrapper>
      <Row>
        <Col xs={12} sm={{ span: 6, offset: 3 }}>
          <PanelComponent>
            <div className="ResetPassword">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <Card.Body>
                  <Form form={form} onSubmit={submitForm}>
                    <Container fluid>
                      <Row>
                        <Col xs={12}>
                          <h1 className="mt-10">
                            {t('containers.reset_password.page_title')}
                          </h1>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <h2 className="small">
                            <p>{t('containers.reset_password.page_body')}</p>
                          </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <ResetPasswordField />
                        </Col>
                      </Row>
                      <Row className="button-margin">
                        <Col className="d-none d-sm-block" sm={5}>
                          <Button
                            id="reset-password-back-btn"
                            type="button"
                            onClick={history.goBack}
                            fullWidth
                            mode={ButtonMode.Secondary}
                            size={ButtonSize.Medium}
                          >
                            {t('containers.reset_password.back_button')}
                          </Button>
                        </Col>
                        <Col xs={12} sm={{ span: 5, offset: 2 }}>
                          <Button
                            id="reset-password-confirm-btn"
                            disabled={submitting || !isValid || isLoading}
                            type="submit"
                            fullWidth
                            mode={ButtonMode.Secondary}
                            size={ButtonSize.Medium}
                          >
                            {t('containers.reset_password.send_button')}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                </Card.Body>
              )}
            </div>
          </PanelComponent>
        </Col>
      </Row>
    </ResetPasswordWrapper>
  );
}

const mapStateToProps = state => ({
  isLoading: isLoadingSelector([ApiConstants.RESET_PASSWORD])(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...apiActions }, dispatch);

const ConnectedResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

export { ConnectedResetPassword as ResetPassword };
