import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import { Button, ButtonSize } from 'edenred-ui';

import withAjax from '@epi-decorators/withAjax';
import { ApiConstants } from '@epi-constants/actions';
import { ErrorPanel } from '@epi-components/ErrorPanel/ErrorPanel';
import Panel from '@epi-components/Panel';
import { Company } from '@epi-components/Company/Company';
import { OrderSummary } from '@epi-components/OrderSummary/OrderSummary';
import { NavigationHeader } from '@epi-components/NavigationHeader/NavigationHeader';
import { LoadingContainer } from '@epi-components/LoadingContainer/LoadingContainer';
import DisplayBillingInformation from '@epi-components/BillingInformation/DisplayBillingInformation';
import { PaymentMethod } from '@epi-forms/PaymentMethod/PaymentMethod';
import { PaymentMethods } from '@epi-constants/paymentMethods';
import { loadPaymentMethods, addNewPaymentToCardOrder } from '@epi-actions/api';
import { getPaymentMethods } from '@epi-selectors/api';
import { OrderType } from '@epi-constants/orderType';
import { getCardPaymentDetailsUrl } from '@epi-repositories/CardOrdersRepository';
import {
  ICardOrderPayment,
  IPaymentData
} from '@epi-models/containers/CardOrderPayment';

import './CardOrderPayment.scss';

function CardOrderPayment({
  fetchGet,
  match,
  fetchPaymentMethods,
  createPayment,
  paymentMethods,
  locale,
  hideConfirmButton
}: ICardOrderPayment) {
  const { t } = useTranslation();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      method: ''
    }
  });
  const { getValues, handleSubmit } = form;

  const [paymentData, updatePaymentData] = useState<IPaymentData>({
    paymentId: match.params.paymentId,
    isLoading: true,
    isProcessing: false,
    billingAddress: null,
    company: null,
    cardOrderSummary: null,
    benefitGroup: null
  });

  const onSubmit = values => {
    createPayment({
      payment: {
        method: values.paymentMethod,
        methodCode: values.paymentMethodCode
      },
      paymentId: paymentData.paymentId
    });
  };

  const handleExternalPayment = () => {
    onSubmit(getValues());
  };

  useEffect(() => {
    const abortController = new AbortController();
    updatePaymentData({
      ...paymentData,
      isLoading: true
    });
    fetchGet(
      getCardPaymentDetailsUrl(match.params.paymentId),
      abortController.signal
    ).then(response => {
      updatePaymentData({
        ...paymentData,
        company: response.company,
        billingAddress: response.billingAddress,
        cardOrderSummary: response.cardOrderSummary,
        benefitGroup: response.benefitGroup,
        isLoading: false
      });
      if (response.cardOrderSummary) {
        fetchPaymentMethods(
          response.cardOrderSummary.orderTotalWithVat,
          locale
        );
      }
    });

    return () => abortController.abort();
  }, [match.params.paymentId]);

  if (
    paymentData.isLoading ||
    !paymentData.company ||
    !paymentData.benefitGroup ||
    !paymentData.cardOrderSummary
  ) {
    return <LoadingContainer isLoading />;
  }
  const { billingAddress, cardOrderSummary, benefitGroup, isProcessing } =
    paymentData;
  const { address, name, businessIdentityCode } = paymentData.company;
  const translationPrefix = 'containers.card_order.summary_and_payment.';

  return (
    <div className="CardOrderPayment">
      <NavigationHeader title={t('containers.card_payment.title')}>
        <FormProvider {...form}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Company
              userCompany={{
                businessIdentityCode,
                name,
                address
              }}
            />
            <DisplayBillingInformation address={billingAddress} />
            <Panel
              title={t(`${translationPrefix}order_summary_header`)}
              subtitle={t(`${translationPrefix}order_summary_subheader`)}
            >
              <OrderSummary
                benefitsSummary={{
                  lunch: {
                    totalAmount: benefitGroup.lunchTotalAmount,
                    beneficiaryNumber: benefitGroup.noOfBeneficiaries,
                    workdays: benefitGroup.workdays,
                    amount: benefitGroup.lunchAmount
                  },
                  transport: {
                    totalAmount: benefitGroup.transportTotalAmount,
                    beneficiaryNumber: benefitGroup.noOfBeneficiaries,
                    amount: benefitGroup.transportAmount
                  },
                  recreational: {
                    totalAmount: benefitGroup.recreationalTotalAmount,
                    beneficiaryNumber: benefitGroup.noOfBeneficiaries,
                    amount: benefitGroup.recreationalAmount
                  }
                }}
                cardOrderSummary={cardOrderSummary}
              />
            </Panel>
            <Panel
              title={t(
                'containers.card_order.summary_and_payment.payment_method_header'
              )}
              subtitle={t(
                'containers.card_order.summary_and_payment.payment_method_subheader'
              )}
            >
              <PaymentMethod
                paymentMethods={{
                  paymentMethods,
                  isLoading: !paymentMethods,
                  isError: false
                }}
                creditCardFeePercent={0}
                amountValue={cardOrderSummary.orderTotalWithVat}
                onClickPaymentMethod={handleExternalPayment}
                isPaymentProcessing={isProcessing}
                disabled={isProcessing}
                disabledMethods={[PaymentMethods.LinkToOtherPerson]}
                hiddenMethods={[
                  PaymentMethods.LinkToOtherPerson,
                  PaymentMethods.PrintInvoicePdf
                ]}
                orderType={OrderType.digital}
              />
              <ErrorPanel
                failedActionName={ApiConstants.CARD_ORDER_PAYMENT_FORM}
              />
            </Panel>
            {!hideConfirmButton && (
              <Button
                id="card-order-payment-submit-button"
                type="submit"
                className="pull-right"
                size={ButtonSize.Medium}
              >
                {t('containers.voucher_order.confirm')}
              </Button>
            )}
          </form>
        </FormProvider>
      </NavigationHeader>
    </div>
  );
}

const connectToStore = connect(
  state => ({
    paymentMethods: getPaymentMethods(state)
  }),
  dispatch => ({
    fetchPaymentMethods: (totalAmount, locale) =>
      dispatch(loadPaymentMethods(OrderType.digital, totalAmount, locale)),
    createPayment: values => dispatch(addNewPaymentToCardOrder(values))
  })
);

export default connectToStore(withAjax()(CardOrderPayment));
