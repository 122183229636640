import React from 'react';
import PropTypes from 'prop-types';
import history from '../../routes/history';
import { BackButton } from '../BackButton/BackButton';
import './NavigationHeader.scss';

export const NavigationHeader = ({
  children,
  withBackButton,
  onClickBack,
  title,
  className
}) => (
  <div className={`NavigationHeader ${className}`}>
    <div
      className={`NavigationHeader-header mb-30 ${
        !withBackButton ? 'with-back-button' : ''
      }`}
    >
      {withBackButton && (
        <BackButton className="btn-back" onClick={onClickBack} />
      )}
      <h2 className="title">{title}</h2>
    </div>
    {children && <div className="NavigationHeader-body">{children}</div>}
  </div>
);

NavigationHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  withBackButton: PropTypes.bool,
  onClickBack: PropTypes.func,
  className: PropTypes.string
};

NavigationHeader.defaultProps = {
  children: undefined,
  withBackButton: false,
  onClickBack: () => history.goBack(),
  className: ''
};
