import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { Route, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from 'edenred-ui';
import { isEmpty } from 'lodash';

import { FormFields } from '@epi-forms/helpers';
import {
  getCompanyInformation,
  getVoucherTypeKey,
  isVoucherOrderLoaded as isVoucherOrderLoadedSelector,
  getDelivery,
  isError as isErrorSelector,
  getOrderSummary,
  getVoucherOrder
} from '@epi-selectors/orderPayment';
import { IVoucherOrderPayment } from '@epi-models/containers/VoucherOrderPayment';

import { OrderType } from '../../constants/orderType';
import { orderPayment as orderPaymentLoadingSelector } from '../../selectors/loading';
import { getApplicationLocale } from '../../selectors/application';
import { ApiConstants } from '../../constants/actions';
import { PaymentMethods } from '../../constants/paymentMethods';
import { DeliveryPicker } from '../../forms/DeliveryPicker/DeliveryPicker';
import { PaymentMethod } from '../../forms/PaymentMethod/PaymentMethod';
import { NotFound } from '../NotFound/NotFound';
import { NavigationHeader } from '../../components/NavigationHeader/NavigationHeader';
import { Company } from '../../components/Company/Company';
import { BenefitSummary } from '../../components/BenefitSummary/BenefitSummary';
import { LoadingContainer } from '../../components/LoadingContainer/LoadingContainer';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { LoadingPanel } from '../../components/LoadingPanel/LoadingPanel';
import * as apiActions from '../../actions/api';

import './VoucherOrderPayment.scss';

function VoucherOrderPayment({
  companyInformation = {},
  isVoucherOrderLoaded,
  voucherTypeKey = '',
  isError,
  apiPaymentMethods,
  isSubmittingForm,
  locale,
  addNewPaymentToVoucher,
  loadOrderFees,
  loadVoucherOrderByPayment,
  loadPaymentMethods
}: IVoucherOrderPayment) {
  const { showWarning, paymentId } = useParams();
  const { t } = useTranslation();
  const voucherOrderPayment = useSelector(getVoucherOrder);
  const [showAlert, setShowAlert] = useState(showWarning);
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.PaymentMethod]: '',
      [FormFields.PaymentMethodCode]: ''
    }
  });
  const {
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isValid }
  } = form;
  const method = watch(FormFields.PaymentMethod);
  const hideConfirmButton =
    method === PaymentMethods.CreditCard || method === PaymentMethods.Online;
  const orderSummary = useSelector(state => getOrderSummary(method)(state));

  const submit = () => {
    addNewPaymentToVoucher({
      paymentId,
      payment: { method }
    });
  };

  const submitExternalPayment = () => {
    addNewPaymentToVoucher({
      paymentId,
      payment: {
        method: getValues(FormFields.PaymentMethod),
        methodCode: getValues(FormFields.PaymentMethodCode)
      }
    });
  };

  const calculateTotalAmount = () => {
    return orderSummary.sumVouchers + orderSummary.handlingFee;
  };

  const handleAlertDismiss = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    loadOrderFees();
    loadVoucherOrderByPayment(paymentId);
  }, []);

  useEffect(() => {
    if (voucherOrderPayment?.totalAmount) {
      loadPaymentMethods(
        OrderType.voucher,
        voucherOrderPayment?.totalAmount,
        locale
      );
    }
  }, [voucherOrderPayment?.totalAmount]);

  return (
    <div className="VoucherOrderPayment">
      {showAlert && (
        <Alert variant="warning" className="my-0 mx-35">
          {t('containers.voucher_payment.unfinished_payment')}
        </Alert>
      )}
      {isError ? (
        <Route component={NotFound} />
      ) : (
        <LoadingContainer isLoading={!isVoucherOrderLoaded}>
          <NavigationHeader title={t('containers.voucher_payment.title')}>
            <FormProvider {...form}>
              <form noValidate onSubmit={handleSubmit(submit)}>
                <div>
                  <Company userCompany={companyInformation} />
                  <DeliveryPicker
                    companyAdress={
                      voucherOrderPayment.delivery?.address ||
                      voucherOrderPayment.delivery?.custom_address
                    }
                  />
                  <BenefitSummary
                    voucherTypeKey={voucherTypeKey}
                    orderSummary={orderSummary}
                    voucherOrderPayment={voucherOrderPayment}
                    showCreditCardFee={orderSummary.creditCardFee > 0}
                    showTitle
                  />
                  <LoadingPanel
                    isLoading={[
                      ApiConstants.CREATE_NEW_PAYMENT,
                      ApiConstants.ADD_NEW_PAYMENT_TO_VOUCHER
                    ]}
                    className="order-payment-method"
                  >
                    <PaymentMethod
                      paymentMethods={apiPaymentMethods}
                      onClickPaymentMethod={() => submitExternalPayment()}
                      disabled={isSubmittingForm}
                      creditCardFeePercent={
                        orderSummary?.creditCardFeePercent as number
                      }
                      amountValue={calculateTotalAmount()}
                      orderType={OrderType.voucher}
                      hiddenMethods={[
                        PaymentMethods.PrintInvoicePdfForTransferMoney
                      ]}
                    />
                  </LoadingPanel>
                  <ErrorPanel
                    failedActionName={ApiConstants.ADD_NEW_PAYMENT_TO_VOUCHER}
                  />
                  {!hideConfirmButton && (
                    <Button
                      id="voucher-order-payment-confirm-btn"
                      type="submit"
                      className="pull-right"
                      isProcessing={isSubmittingForm}
                      disabled={!isValid || !isEmpty(errors)}
                    >
                      {t('containers.voucher_order.confirm')}
                    </Button>
                  )}
                </div>
              </form>
            </FormProvider>
          </NavigationHeader>
        </LoadingContainer>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  apiPaymentMethods: state.apiPaymentMethods,
  locale: getApplicationLocale(state),
  companyInformation: getCompanyInformation(state),
  voucherTypeKey: getVoucherTypeKey(state),
  isVoucherOrderLoaded: isVoucherOrderLoadedSelector(state),
  delivery: getDelivery(state),
  isError: isErrorSelector(state),
  isSubmittingForm: orderPaymentLoadingSelector(state)
});

const mapDispatchToProps = dispatch => bindActionCreators(apiActions, dispatch);

const ConnectedVoucherOrderPayment = connect(
  mapStateToProps,
  mapDispatchToProps
)(VoucherOrderPayment);

export { ConnectedVoucherOrderPayment as VoucherOrderPayment };
