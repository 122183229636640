import React from 'react';
import { Checkbox, Pill, PillType, Box } from 'edenred-ui';
import { useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { BenefitTypeCheckboxes } from './BenefitTypeCheckboxes';
import { getOther } from '@epi-pages/DownloadReports/helpers/getOther';
import { FormFields } from '@epi-forms/helpers';

export const DataColumnsSection = () => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const { gray25 } = useTheme();

  const dataColumnPills = watch(getOther(FormFields.Columns));
  const dataScope = watch(getOther(FormFields.DataScope));

  const handleAction = (id: string) => {
    const newDataColumnPills = dataColumnPills.map(item =>
      item.id === id ? { ...item, active: !item.active } : item
    );
    setValue(getOther(FormFields.Columns), newDataColumnPills);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderBottom={1}
      borderColor={gray25}
    >
      <Box display="flex" gap={1.2} py={5} flexWrap="wrap">
        {dataColumnPills.map(p => {
          return dataScope[p.group] ? (
            <Pill
              key={nanoid()}
              type={PillType.Basic}
              id={`data-columns-${p.id}`}
              label={t(`containers.reports.columns.${p.id}`)}
              isActive={p.active}
              action={() => handleAction(p.id)}
              icon={<Checkbox noPadding checked={p.active} />}
            />
          ) : null;
        })}
      </Box>
      <BenefitTypeCheckboxes />
    </Box>
  );
};
