import React from 'react';
import { Box, TitleText, Button, ButtonMode } from 'edenred-ui';
import { useFormContext, useController } from 'react-hook-form';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FormFields } from '@epi-forms/helpers';
import { ITerminationDate } from '@epi-models/pages/Beneficiaries';
import { maxLength } from '@epi-helpers/formHelpers/validators';

import { PersonalDataField } from '../BeneficiaryDetail/components';
import { TerminationDateDialog } from './components/TerminationDateDialog';
import { useTerminationDate } from './hooks';

export const TerminationDate = ({
  showTerminationButton = true
}: ITerminationDate) => {
  const { t } = useTranslation();
  const { gray85, gray70, sizeExtraSmall, fontSize, warningColor } = useTheme();
  const { timePeriodDialog, handleClose, handleOpen } = useTerminationDate();
  const { control } = useFormContext();
  const {
    field: { value: EoB }
  } = useController({
    name: FormFields.EndOfBenefit,
    control
  });

  return (
    <Box>
      <TitleText noMargin color={gray85} fontSize={fontSize} fontWeight={600}>
        {t('containers.beneficiaries.detail.end_of_benefit_title')}
      </TitleText>
      {showTerminationButton && (
        <>
          <Box fontSize={sizeExtraSmall} color={gray70} mt={1}>
            {t('containers.beneficiaries.detail.end_of_benefit_info')}
          </Box>
          <Box mt={2}>
            <Button
              onClick={handleOpen}
              id="button"
              mode={ButtonMode.Secondary}
              style={{
                borderColor: warningColor,
                color: warningColor
              }}
            >
              {t('containers.beneficiaries.detail.end_of_benefit_button')}
            </Button>
          </Box>
        </>
      )}
      {EoB && (
        <Box mt={3}>
          <PersonalDataField
            label={t('containers.beneficiaries.detail.end_of_benefit')}
            name={FormFields.EndOfBenefit}
            disabled
            validate={[maxLength(20)]}
          />
          <PersonalDataField
            label={t('containers.beneficiaries.detail.end_of_founds')}
            name={FormFields.EndOfFunds}
            disabled
            validate={[maxLength(20)]}
          />
        </Box>
      )}
      <TerminationDateDialog
        isOpen={timePeriodDialog}
        handleClose={handleClose}
      />
    </Box>
  );
};
