//@ts-nocheck
import React from 'react';
import { Box } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import MomentLocaleUtils, {
  formatDate,
  parseDate
} from 'react-day-picker/moment';
import { datePickerApiFormat } from '@epi-constants/dateFormats';
import { TODAY } from '@epi-constants/downloadReports';
import { DateRangePickerStyles } from '@epi-components/DateRangePicker/DateRangePicker.styles';
import { ITerminationDatePicker } from '@epi-models/pages/Beneficiaries';

import { useTerminationDatePicker } from '../hooks';

export const TerminationDatePicker = (props: ITerminationDatePicker) => {
  const { isError, dayValue } = props;
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const { day, ref, handleChange, handleOnDayClick } = useTerminationDatePicker(
    { ...props }
  );

  return (
    <DateRangePickerStyles>
      <Box id="date-from" display="flex" flexDirection="column">
        <label>
          {t('containers.beneficiaries.detail.termination_modal_modal')}
        </label>
        {isError && (
          <Box color={'red'} fontSize={12}>
            {t(
              'containers.beneficiaries.detail.end_of_founds_calendar_err_msg'
            )}
          </Box>
        )}
        <DayPickerInput
          ref={ref}
          value={dayValue || day}
          format={datePickerApiFormat}
          placeholder={datePickerApiFormat}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [day],
            disabledDays: { before: TODAY },
            numberOfMonths: 1,
            keepFocus: true,
            onDayClick: handleOnDayClick,
            localeUtils: MomentLocaleUtils,
            locale: language
          }}
          onDayChange={handleChange}
          showOverlay={false}
          showOutsideDays
          hideOnDayClick={false}
        />
      </Box>
    </DateRangePickerStyles>
  );
};
