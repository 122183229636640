/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { parse } from 'querystring';
import { LoadingSpinner } from 'edenred-ui';

import { IMaksturvaNewPaymentPage } from '@epi-models/containers/MaksturvaNewPaymentPage';

import { getApiPayments } from '../../selectors/api';
import { replacePage } from '../../actions/navigation';

import { sendFormData } from './FormHelper';

import './MaksturvaNewPayment.scss';

const connectToStore = connect(
  state => ({
    apiPayments: getApiPayments(state)
  }),
  dispatch => ({
    cancelPayment: paymentId =>
      dispatch(replacePage(`order/status/${paymentId}`)),
    submitPayment: (actionUrl, formParams) =>
      sendFormData(actionUrl, formParams, 'POST')
  })
);

export function MaksturvaNewPaymentPage({
  apiPayments,
  location,
  cancelPayment,
  submitPayment
}: IMaksturvaNewPaymentPage) {
  const paymentDefined =
    !!apiPayments &&
    !!apiPayments?.newPaymentParams?.formParams &&
    !!apiPayments?.newPaymentParams?.actionUrl;

  useEffect(() => {
    if (paymentDefined && apiPayments.newPaymentParams) {
      const { actionUrl, formParams } = apiPayments.newPaymentParams;
      if (formParams && actionUrl) {
        submitPayment(actionUrl, formParams, 'POST');
      }
    } else {
      const queryObject = parse(location.search.substring(1));
      cancelPayment(queryObject.paymentId);
    }
  }, [paymentDefined]);

  return (
    <div className="MaksturvaNewPayment">
      <LoadingSpinner />
    </div>
  );
}

export const MaksturvaNewPayment = connectToStore(MaksturvaNewPaymentPage);
