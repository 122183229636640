import storage from '@epi-helpers/StorageHelper';

export const setColumnInitialValue = (columnId: string) => {
  const selectedByDefault = [
    'firstName',
    'lastName',
    'benefitType',
    'loadAmount'
  ];
  const fromStorage = storage.get(`report${columnId}`);
  if (typeof fromStorage === 'boolean') {
    return fromStorage;
  }
  return selectedByDefault.includes(columnId);
};
