import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { goToPage } from '@epi-actions/navigation';
import config from '@epi-config';
import {
  ReportTypeWithoutVirike,
  ReportTypeVirike,
  REPORTS_QUERY_NAME,
  REPORTS_SEARCH_NAME
} from '@epi-constants/externalReport';

const { enableVirikeBenefit } = config;

const ReportType = enableVirikeBenefit
  ? ReportTypeVirike
  : ReportTypeWithoutVirike;

export const useExternalReportsTabs = () => {
  const [showFooter, setShowFooter] = useState(false);
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();

  const handleFooterVisibility = tabName =>
    setShowFooter(tabName === ReportType.reports);

  const handleURLChange = tabName =>
    dispatch(goToPage(pathname + REPORTS_QUERY_NAME + tabName));

  const handleOnTabChange = tabName => {
    handleFooterVisibility(tabName);
    handleURLChange(tabName);
  };

  const urlParams = new URLSearchParams(search);
  const indexType = urlParams.get(REPORTS_SEARCH_NAME);
  const defaultTabName =
    indexType && ReportType[indexType]
      ? ReportType[indexType]
      : ReportType.transactions;

  return {
    showFooter,
    handleOnTabChange,
    defaultTabName
  };
};
