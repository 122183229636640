import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { TextField } from 'edenred-ui';

import { createRules, FormFields } from '@epi-forms/helpers';
import {
  maxLength,
  required as requiredValidation
} from '@epi-helpers/formHelpers/validators';
import { IPersonalDataField } from '@epi-models/pages/Beneficiaries';

export const PersonalDataField = ({
  label,
  validate,
  name,
  disabled,
  isAddMode = false,
  required = false
}: IPersonalDataField) => {
  const { control } = useFormContext();
  const defaultValidators = [requiredValidation, maxLength(50)];
  const rules = createRules(validate || defaultValidators);

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const {
    field: { value: isEditMode }
  } = useController({
    name: FormFields.EditMode,
    control
  });

  const isReadOnly = isAddMode ? false : disabled || !isEditMode;
  const setValue = () => {
    if (value) return value;
    if (isAddMode) return '';
    if (isEditMode) return '';
    return 'None';
  };

  return (
    <TextField
      id={name}
      label={label}
      value={setValue()}
      onChange={onChange}
      readOnly={isReadOnly}
      error={!!error}
      helperText={error?.message}
      required={required}
    />
  );
};
