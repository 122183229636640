import React from 'react';
import { useTheme } from 'styled-components';
import {
  TitleText,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup
} from 'edenred-ui';
import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getPayroll } from '@epi-pages/DownloadReports/helpers';
import { Benefit } from '@epi-constants/downloadReports';
import { FormFields } from '@epi-forms/helpers';

const name = getPayroll(FormFields.Benefit);

export const BenefitReportsGroup = () => {
  const { sizeMedium, fontSemiBold, gray85, gray10 } = useTheme();
  const { control } = useFormContext();
  const { t } = useTranslation();

  const {
    field: { value, onChange }
  } = useController({
    name,
    control
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={6}
      mt={6}
      pb={5}
      borderBottom={1}
      borderColor={gray10}
    >
      <TitleText
        fontSize={sizeMedium}
        fontWeight={fontSemiBold}
        color={gray85}
        noMargin
      >
        {t('download_reports.lunch_deduction.benefit_title')}
      </TitleText>
      <Box fontSize={16} fontWeight={400} position="relative" top={3}>
        <RadioGroup onChange={onChange} value={value}>
          <Box display="flex" gap={6}>
            <FormControlLabel
              value={Benefit.Lounari}
              control={<Radio />}
              label={t('download_reports.lunch_deduction.benefit_lounari')}
              id="choose-as-lounari-radio"
            />
            <FormControlLabel
              value={Benefit.Virike}
              control={<Radio />}
              label={t('download_reports.lunch_deduction.benefit_transport')}
              id="choose-as-virike-radio"
              disabled
            />
          </Box>
        </RadioGroup>
      </Box>
    </Box>
  );
};
