export const InvoicingMethod = {
  Email: 'Email',
  EInvoice: 'EInvoice',
  Paper: 'Paper'
};

export const FormArrays = {
  ContactPersons: 'contactPersons'
};

export const FormFields = {
  AcceptTerms: 'acceptTerms',
  ActiveBenefits: 'activeBenefits',
  AdditionalMessage: 'additionalMessage',
  Address: 'address',
  AddressId: 'addressId',
  AddressLine: 'addressLine',
  Benefit: 'benefit',
  Benefits: 'benefits',
  BeneficiariesAmount: 'beneficiariesAmount',
  BusinessId: 'businessId',
  City: 'city',
  CostCenterNumber: 'costCenter',
  Columns: 'columns',
  CompanyName: 'companyName',
  ConfirmPassword: 'confirmPassword',
  CopyCompanyAddress: 'copyCompanyAddress',
  CopyContactPerson: 'copyContactPerson',
  Country: 'country',
  CustomerNumber: 'customerNumber',
  CreatedAt: 'createdAt',
  DataScope: 'dataScope',
  DeliveryMethod: 'deliveryMethod',
  DeliveryAddress: 'deliveryAddress',
  DeliveryCity: 'deliveryCity',
  DeliveryCompanyName: 'deliveryCompanyName',
  DeliveryCountry: 'deliveryCountry',
  DeliveryZipCode: 'deliveryZipCode',
  EditMode: 'EditMode',
  EmployeeNumber: 'employeeNumber',
  EmployeeType: 'EmployeeType',
  EndOfBenefit: 'endOfBenefit',
  EndOfFunds: 'endOfFunds',
  DownloadAs: 'downloadAs',
  EInvoice: 'eInvoice',
  EInvoiceAddress: 'eInvoiceAddress',
  EmailAddress: 'emailAddress',
  EmployeeData: 'employeeData',
  EndDate: 'endDate',
  FirstName: 'firstName',
  FromMonth: 'fromMonth',
  InvoicingContactPerson: 'invoicingContactPerson',
  InvoicingCostCenter: 'invoicingCostCenter',
  InvoicingEmailAddress: 'invoicingEmailAddress',
  InvoicingMethod: 'invoicingMethod',
  JobTitle: 'jobTitle',
  KeepLoggedIn: 'keepLoggedIn',
  LastName: 'lastName',
  Loads: 'loads',
  LoadStatuses: 'loadStatuses',
  NewPassword: 'newPassword',
  MoneyAmount: 'moneyAmount',
  NumberOfEmployees: 'numberOfEmployees',
  Operator: 'operator',
  Other: 'other',
  Password: 'password',
  Payroll: 'payroll',
  PaymentMethod: 'paymentMethod',
  PaymentMethodCode: 'paymentMethodCode',
  PhoneNumber: 'phoneNumber',
  PersonID: 'personId',
  PersonalID: 'personalId',
  PickerStartDate: 'pickerStartDate',
  PickerEndDate: 'pickerEndDate',
  PostCode: 'postCode',
  RightToSign: 'rightToSign',
  RequestDownloadReport: 'requestDownloadReport',
  Report: 'report',
  SelectedID: 'selectedId',
  StartDate: 'startDate',
  ToMonth: 'toMonth',
  Username: 'username',
  UsernameOrEmail: 'usernameOrEmail',
  ValidateCompanyInformation: 'validateCompanyInformation',
  VoucherId: 'voucherId',
  VoucherQuantity: 'voucherQuantity',
  VoucherWorth: 'voucherWorth',
  WageType: 'wageType',
  WithoutReferenceNumber: 'withoutReferenceNumber',
  ZipCode: 'zipCode'
};

export function withFormNameContext(context, name) {
  return context ? `${context}.${name}` : name;
}
