import React, { StrictMode } from 'react';
import PropTypes from 'prop-types';

const StrictModeContainer = ({ children }) => {
  if (process.env.NODE_ENV === 'development') {
    return <StrictMode>{children}</StrictMode>;
  }
  return children;
};

StrictModeContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export { StrictModeContainer as StrictMode };
