//@ts-nocheck
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Button, ButtonSize, Box, NotificationErrorIcon } from 'edenred-ui';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import {
  UsernameField,
  FirstNameField,
  LastNameField,
  EmailAddressField,
  PhoneNumberField
} from '@epi-forms/controls';
import { FormFields } from '@epi-forms/helpers';
import * as profileActions from '@epi-actions/profile';
import * as apiActions from '@epi-actions/api';
import { hasRole, getLoginState, getProfile } from '@epi-selectors/login';
import { isProduction, isQa } from '@epi-selectors/environment';
import { Roles } from '@epi-constants/roles';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import { fetchPost as Post } from '@epi-helpers/FetchHelpers';
import config from '@epi-config';
import { requestConfirmationLinkApiUrl } from '@epi-repositories/EmailRepository';
import { showNotification } from '@epi-actions/overlays';
import { IUserInformation } from '@epi-models/containers/Profile';

const ResendButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const { disableEditOptionsForOA } = config;

function UserInformation({
  getUserInformation,
  pristine,
  submitting,
  isSignatory,
  addSignatoryRoleToUser,
  showSignatorySwitch,
  updateUserInformation
}: IUserInformation) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileInfo = useSelector(getProfile);
  const { isUserImpersonate } = useSelector(getLoginState);
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Username]: '',
      [FormFields.FirstName]: '',
      [FormFields.LastName]: '',
      [FormFields.EmailAddress]: '',
      [FormFields.PhoneNumber]: ''
    }
  });

  const {
    trigger,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
    watch,
    clearErrors
  } = form;

  const userNameValue = watch(FormFields.Username);

  useEffect(() => {
    getUserInformation();
  }, []);

  useEffect(() => {
    setValue(FormFields.Username, profileInfo.username);
    setValue(FormFields.FirstName, profileInfo.firstName);
    setValue(FormFields.LastName, profileInfo.lastName);
    setValue(FormFields.EmailAddress, profileInfo.emailAddress);
    setValue(FormFields.PhoneNumber, profileInfo.phoneNumber);
  }, [profileInfo]);

  const onSubmit = values => {
    const updatedValues = { ...values, isUserImpersonate };
    updateUserInformation(updatedValues);
  };

  const handleKeyDown = (e, callback) => {
    if (e.key === 'Enter' && !e.shiftKey && !pristine && !submitting) {
      e.preventDefault();
      callback();
    }
  };

  useEffect(() => {
    if (profileInfo?.emailAddress) {
      trigger();
    }
  }, [profileInfo]);

  useEffect(() => {
    if (profileInfo.username === userNameValue) {
      clearErrors();
    }
  }, [userNameValue]);

  const requestNewLink = () => {
    const onSuccess = () => {
      dispatch(
        showNotification(
          { message: 'email_verification.request_new_link_success' },
          'success'
        )
      );
    };
    const onFailure = () => {
      dispatch(
        showNotification(
          { message: 'email_verification.get_new_verification_link_fail' },
          'error'
        )
      );
    };

    return Post(
      requestConfirmationLinkApiUrl,
      { username: profileInfo.username },
      onSuccess,
      onFailure
    );
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={e => {
          handleKeyDown(e, handleSubmit(onSubmit));
        }}
      >
        <Container fluid>
          <Row className="username-row">
            <Col>
              <UsernameField
                validateAvailability={profileInfo.username !== userNameValue}
                label="forms.user_information.username"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Box paddingBottom="20px">
                <FirstNameField />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col>
              <Box paddingBottom="20px">
                <LastNameField />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col>
              <Box paddingBottom="20px">
                <EmailAddressField
                  label={t('controls.email_address_label')}
                  unverifiedInfo={
                    !profileInfo.isEmailVerified && (
                      <Box display="flex" alignItems="center">
                        <Box mr="6px">
                          <NotificationErrorIcon height="20px" />
                        </Box>
                        <Box>
                          <Trans
                            i18nKey="email_verification.unverified_email_help"
                            components={{
                              a: <ResendButton onClick={requestNewLink} />
                            }}
                          />
                        </Box>
                      </Box>
                    )
                  }
                />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col>
              <Box paddingBottom="20px">
                <PhoneNumberField />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              {showSignatorySwitch && (
                <button
                  disabled={isSignatory}
                  className="btn btn-default pull-left"
                  type="button"
                  onClick={() => {
                    addSignatoryRoleToUser(0);
                  }}
                >
                  {t('forms.signing_right.label')}
                </button>
              )}
              <Button
                id="user-info-submit-btn"
                type="submit"
                aria-hidden
                disabled={
                  !isValid ||
                  !isEmptyObj(errors) ||
                  Boolean(disableEditOptionsForOA)
                }
                className="pull-right"
                size={ButtonSize.Medium}
              >
                {t('forms.user_information.submit')}
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
    </FormProvider>
  );
}

const mapStateToProps = state => ({
  profile: state.profile,
  usernameAvailability: state.usernameAvailability,
  initialValues: state.profile,
  isSignatory: hasRole(Roles.signatory)(state),
  showSignatorySwitch:
    (!isProduction() || isQa()) && !hasRole(Roles.signatory)(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...profileActions, ...apiActions }, dispatch);

const ConnectedUserInformation = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserInformation));

export { ConnectedUserInformation as UserInformation };
