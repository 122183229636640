import React from 'react';
import { useTranslation } from 'react-i18next';

import { getSingleErrorDataString } from '@epi-helpers/ErrorDataHelper';
import { ErrorSeverity } from '@epi-constants/errorSeverity';
import { IErrorDataItem } from '@epi-models/containers/ErrorData';

export const ErrorDataItem = ({ errorData }: IErrorDataItem) => {
  const { t } = useTranslation();
  const isWarning = errorData && errorData.severity === ErrorSeverity.Warning;

  const getTranslatedErrorString = () => {
    if (errorData.value) {
      return errorData.translated ? errorData.value : t(errorData.value);
    }

    return getSingleErrorDataString(errorData);
  };

  return (
    <div className={`${isWarning ? 'text-warning' : 'text-danger'}`}>
      <span>{t(getTranslatedErrorString()) || getTranslatedErrorString()}</span>
    </div>
  );
};
