import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PagePanel, Button, ButtonSize, EdenredIcon } from 'edenred-ui';

import { BenefitSummaryContent } from '../BenefitSummaryContent/BenefitSummaryContent';
import { RoundButton } from '../RoundButton/RoundButton';
import { LoadingContainer } from '../LoadingContainer/LoadingContainer';
import * as navigationActions from '../../actions/navigation';

import './BenefitSummary.scss';

const LOCALE_PREFIX = 'components.benefit_summary';
const BenefitSummary = ({
  goToEditPage,
  showTitle,
  showCreditCardFee,
  voucherTypeKey,
  isLoading,
  orderSummary,
  voucherOrderPayment
}) => {
  const { t } = useTranslation();

  return (
    <PagePanel
      title={
        showTitle ? t('containers.voucher_order.benefit_summary_header') : null
      }
      id="BenefitSummary"
      className="BenefitSummary px-10"
    >
      {showTitle && goToEditPage && (
        <span className="visible-xs-inline">
          <RoundButton
            id="benefit-summary-edit-icon-btn"
            icon={<EdenredIcon />}
            className="pull-right"
            onClick={goToEditPage}
          />
        </span>
      )}
      <LoadingContainer isLoading={isLoading}>
        <Row>
          <Col className="px-45" lg={11}>
            <BenefitSummaryContent
              showTitle={showTitle}
              showCreditCardFee={showCreditCardFee}
              voucherTypeKey={voucherTypeKey}
              orderSummary={orderSummary}
              voucherOrderPayment={voucherOrderPayment}
            />
          </Col>
        </Row>
        {goToEditPage && (
          <Row className="hidden-xs">
            <Col>
              <Button
                id="benefit-summary-edit-btn"
                className="btn-edit pull-right"
                onClick={goToEditPage}
                size={ButtonSize.Medium}
              >
                {t(`${LOCALE_PREFIX}.edit`)}
              </Button>
            </Col>
          </Row>
        )}
      </LoadingContainer>
    </PagePanel>
  );
};

BenefitSummary.propTypes = {
  voucherTypeKey: PropTypes.string.isRequired,
  orderSummary: PropTypes.object.isRequired,
  goToEditPage: PropTypes.func,
  showCreditCardFee: PropTypes.bool,
  showTitle: PropTypes.bool,
  isLoading: PropTypes.bool,
  voucherOrderPayment: PropTypes.object
};

BenefitSummary.defaultProps = {
  showCreditCardFee: false,
  isLoading: false,
  showTitle: false,
  editPage: null
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const ConnectedBenefitSummary = connect(
  null,
  mapDispatchToProps
)(BenefitSummary);

export {
  ConnectedBenefitSummary as BenefitSummary,
  BenefitSummary as BenefitSummaryTest
};
