import React, { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { reorderData as reorderDataLoadingSelector } from '@epi-selectors/loading';
import { isError as isErrorSelector } from '@epi-selectors/voucherReorder';
import * as voucherReorderActions from '@epi-actions/voucherReorder';

import { LoadingData } from 'src/scripts/routes/LoadingData';

import { OrderAmount } from './OrderAmount';

export const OrderAmountLoadingData = (props: PropsWithChildren) => {
  const { orderId } = useParams();
  return (
    <LoadingData
      {...props}
      loadAction={() => voucherReorderActions.loadReorderData(orderId)}
      loadingSelector={state => reorderDataLoadingSelector(state, orderId)}
      errorSelector={state => isErrorSelector(state)}
    >
      <OrderAmount />
    </LoadingData>
  );
};
