import React from 'react';
import {
  Button,
  Box,
  ButtonMode,
  ButtonSize,
  Checkbox,
  FormControlLabel
} from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { IAddBeneficiaryActions } from '@epi-models/pages/Beneficiaries';

export const AddBeneficiaryActions = ({
  addAnotherEmployee,
  isProcessing,
  toggleAddAnotherEmployee,
  handleClose,
  handleSubmitFormExecute,
  disableSubmit
}: IAddBeneficiaryActions) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between" mx={5} gap={5}>
      <Box display="flex" alignItems="flex-end" fontSize={16} fontWeight={400}>
        <FormControlLabel
          control={
            <Checkbox
              checked={addAnotherEmployee}
              onChange={toggleAddAnotherEmployee}
            />
          }
          label={t('containers.beneficiaries.add_employee.add_another')}
          labelPlacement="end"
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" gap={5}>
        <Button
          id="beneficiary-detail-cancel-btn"
          onClick={handleClose}
          mode={ButtonMode.Link}
        >
          {t('containers.beneficiaries.detail.cancel')}
        </Button>
        <Button
          id="beneficiary-detail-submit-btn"
          onClick={handleSubmitFormExecute}
          mode={ButtonMode.Primary}
          size={ButtonSize.Large}
          disabled={disableSubmit}
          minWidth={130}
          isProcessing={isProcessing}
        >
          {t('containers.beneficiaries.detail.save')}
        </Button>
      </Box>
    </Box>
  );
};
