import React from 'react';
import { Benefits, PersonalData } from '../components';
import { useTranslation } from 'react-i18next';
import { BenefitsTabName } from '@epi-constants/beneficiary';

export const useBeneficiaryTab = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      name: BenefitsTabName.personalData,
      label: t('containers.beneficiaries.detail.personal_data'),
      id: 'tab-personal-data',
      body: <PersonalData />
    },
    {
      name: BenefitsTabName.benefits,
      label: t('containers.beneficiaries.detail.benefits'),
      id: 'tab-benefits',
      body: <Benefits />
    }
  ];
  return {
    tabs
  };
};
