import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFields } from '@epi-forms/helpers';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import { getBeneficiaryPayload } from '@epi-pages/Beneficiaries/helpers/getBeneficiaryPayload';
import { IAddBeneficiarySlideout } from '@epi-models/pages/Beneficiaries';
import { UNIQUE, FIELDS_TO_WATCH } from '@epi-constants/beneficiary';
import {
  addBenefitsDataMapper,
  setBenefitsDataForCreateEmployee
} from '@epi-helpers/BeneficiaryHelper';

export const useAddBeneficiarySlideout = ({
  expanded,
  isProcessing,
  setExpanded,
  addEmployeeAction,
  currentlySetBenefitValues
}: IAddBeneficiarySlideout) => {
  const { t } = useTranslation();
  const [addAnotherEmployee, setAddAnotherEmployee] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const form = useForm({
    mode: 'onChange',
    defaultValues: addBenefitsDataMapper(currentlySetBenefitValues)
  });

  const {
    getValues,
    reset,
    watch,
    trigger,
    setError,
    setValue,
    formState: { errors, isDirty, dirtyFields }
  } = form;

  const isEmptyField = watch(FIELDS_TO_WATCH);

  const toggleAddAnotherEmployee = () =>
    setAddAnotherEmployee(prevState => !prevState);

  const handleClose = () => {
    if (!isDirty) {
      setExpanded(false);
      setShowCloseConfirmation(false);
      reset();
    } else {
      setShowCloseConfirmation(true);
    }
  };

  const handleSubmitFormExecute = () => {
    if (isEmptyField.includes('')) {
      trigger();
    } else {
      addEmployeeAction(
        getBeneficiaryPayload(getValues()),
        addAnotherEmployee,
        reset
      )
        .then(() => setExpanded(false))
        .catch(err => {
          const uniqueEmployerNumber =
            err.content && err.content.errors[0].message === UNIQUE;
          if (uniqueEmployerNumber) {
            setError(FormFields.EmployeeNumber, {
              type: 'custom',
              message: t('messages.employee_number')
            });
          }
        });
    }
  };

  const handleCloseConfirmation = () => setShowCloseConfirmation(false);

  const confirmCloseWithoutSaving = () => {
    setShowCloseConfirmation(false);
    reset();
    setExpanded(false);
  };

  const confirmSaving = () => {
    setShowCloseConfirmation(false);
  };

  const disableSubmit =
    !isEmptyObj(errors) || !isDirty || isEmptyObj(dirtyFields) || isProcessing;

  useEffect(() => {
    if (!expanded) {
      reset();
      setValue(
        FormFields.Benefits,
        setBenefitsDataForCreateEmployee(currentlySetBenefitValues)
      );
    }
  }, [expanded]);

  useEffect(() => {
    if (currentlySetBenefitValues) {
      setValue(
        FormFields.Benefits,
        setBenefitsDataForCreateEmployee(currentlySetBenefitValues)
      );
    }
  }, [currentlySetBenefitValues]);

  return {
    handleClose,
    addAnotherEmployee,
    toggleAddAnotherEmployee,
    handleSubmitFormExecute,
    disableSubmit,
    form,
    showCloseConfirmation,
    handleCloseConfirmation,
    confirmCloseWithoutSaving,
    confirmSaving
  };
};
