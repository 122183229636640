import React from 'react';
import { bindActionCreators } from 'redux';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as navigationActions from '../../actions/navigation';
import './FixedPanel.scss';

const FixedPanel = ({ children, className }) => (
  <Card className={`FixedPanel ${className}`}>
    <Card.Body>{children}</Card.Body>
  </Card>
);

FixedPanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

FixedPanel.defaultProps = {
  className: ''
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const ConnectedFixedPanel = connect(null, mapDispatchToProps)(FixedPanel);

export { ConnectedFixedPanel as FixedPanel };
