import React from 'react';
import { SimpleButton, EditIcon } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { IBenefitGroupTile } from '@epi-models/shared';

import * as Style from './BenefitGroupTile.styles';

export const BenefitGroupTile = ({
  groupName,
  maxAmountPerPeriod,
  index,
  onTileClick,
  onEditClick,
  benefitType,
  noHover = false
}: IBenefitGroupTile) => {
  const { t } = useTranslation();
  const maxAmountPerPeriodDisplay = maxAmountPerPeriod.toFixed(2);

  return (
    <Style.BenefitGroupTileContainer
      id={`benefit-group-${index}`}
      onClick={onTileClick}
      noHover={noHover}
    >
      <Style.BenefitGroupTileHeading>
        <Style.BenefitGroupTileTitle>{groupName}</Style.BenefitGroupTileTitle>
        {onEditClick && (
          <Style.BenefitGroupTileActions>
            <SimpleButton
              id={`benefit-group-edit-${index}`}
              icon={<EditIcon />}
              onClick={e => {
                e.stopPropagation();
                onEditClick();
              }}
            />
          </Style.BenefitGroupTileActions>
        )}
      </Style.BenefitGroupTileHeading>
      <Style.BenefitGroupLimit>
        <span>
          {t(`components.manage_benefit_${benefitType}.detail_limit_label`)}
        </span>
        <Style.BenefitGroupLimitValue
          id={`benefit-group-daily-limit-${index}`}
        >{`${maxAmountPerPeriodDisplay} €`}</Style.BenefitGroupLimitValue>
      </Style.BenefitGroupLimit>
    </Style.BenefitGroupTileContainer>
  );
};
