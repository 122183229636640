import React from 'react';
import { useTranslation } from 'react-i18next';

import PlusIcon from '@epi-assets/images/Plus.svg';
import { IAddBenefitGroupTile } from '@epi-models/containers/ManageBenefitGroups';

import * as Style from './AddBenefitGroupTile.styles';

export function AddBenefitGroupTile({ onClick }: IAddBenefitGroupTile) {
  const { t } = useTranslation();

  return (
    <Style.AddBenefitGroupTileContainer onClick={onClick}>
      <Style.AddBenefitGroup>
        <img src={PlusIcon} alt="plus icon" />
        <span>{t('components.manage_benefit_lunch.detail_create_title')}</span>
      </Style.AddBenefitGroup>
    </Style.AddBenefitGroupTileContainer>
  );
}
