import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from 'edenred-ui';

import { createRules, useOnBlurTrim } from '@epi-forms/helpers';
import { maxLength, required } from '@epi-helpers/formHelpers/validators';
import { IBenefitGroupNameField } from '@epi-models/containers/ManageBenefitGroups';

import { ManageBenefitLunchFormFields } from '../../helpers';

export function BenefitGroupNameField({
  readOnly = false,
  label = ''
}: IBenefitGroupNameField) {
  const name = ManageBenefitLunchFormFields.BenefitGroupName;
  const { control } = useFormContext();
  const onBlurTrim = useOnBlurTrim(name);
  const validators = [required, maxLength(60)];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      label={label}
      readOnly={readOnly}
      value={value}
      id="group-name-input"
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onBlur={onBlurTrim}
      onChange={setValue}
    />
  );
}
