import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonSize } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

const OrderButton = ({
  isLoading,
  currentPage,
  totalPages,
  onClick,
  disabled
}) => {
  const { t } = useTranslation();

  return (
    <Button
      id="order-btn"
      className="pull-right"
      type="submit"
      aria-hidden
      disabled={isLoading || disabled}
      onClick={() => onClick()}
      size={ButtonSize.Medium}
    >
      {currentPage < totalPages
        ? t('containers.voucher_order.continue')
        : t('containers.voucher_order.confirm')}
    </Button>
  );
};

OrderButton.propTypes = {
  isLoading: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired
};

OrderButton.defaultProps = {
  isLoading: false,
  onClick: () => {}
};

const OrderButtonWthI18n = OrderButton;

export { OrderButtonWthI18n as OrderButton };
