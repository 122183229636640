import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFields } from '@epi-forms/helpers';
import { benefitsDataMapper } from '@epi-helpers/BeneficiaryHelper';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import { getBeneficiaryPayload } from '@epi-pages/Beneficiaries/helpers/getBeneficiaryPayload';
import { IBeneficiaryDetailSlideout } from '@epi-models/pages/Beneficiaries';
import { UNIQUE } from '@epi-constants/beneficiary';

export const useBeneficiaryDetailSlideout = ({
  beneficiary,
  editingMode,
  setExpanded,
  setEditingMode,
  editEmployeeAction
}: IBeneficiaryDetailSlideout) => {
  const { t } = useTranslation();
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const form = useForm({
    mode: 'onChange',
    defaultValues: benefitsDataMapper(beneficiary, editingMode)
  });

  const {
    getValues,
    setValue,
    reset,
    setError,
    watch,
    formState: { errors, isDirty }
  } = form;

  const disableSubmit = () => {
    if (!isEmptyObj(errors)) return true;
    if (
      watch(FormFields.EndOfBenefit) !== null &&
      !watch(FormFields.EndOfBenefit)
    )
      return false;
    if (!isDirty) return true;
    return false;
  };

  const handleClose = () => {
    if ((isEmptyObj(errors) && !editingMode) || !isDirty) {
      setExpanded(false);
      setEditingMode(false);
      reset(benefitsDataMapper(beneficiary, editingMode));
    } else {
      setShowCloseConfirmation(true);
    }
  };

  const handleSubmitFormExecute = () => {
    editEmployeeAction(getBeneficiaryPayload(getValues()))
      .then(() =>
        reset(
          {},
          {
            keepErrors: true,
            keepValues: true
          }
        )
      )
      .catch(err => {
        const uniqueEmployerNumber =
          err.content && err.content.errors[0].message === UNIQUE;
        if (uniqueEmployerNumber) {
          setError(FormFields.EmployeeNumber, {
            type: 'custom',
            message: t('messages.employee_number')
          });
        }
      });
  };

  const confirmCloseWithoutSaving = () => {
    setShowCloseConfirmation(false);
    reset(benefitsDataMapper(beneficiary, editingMode));
    setEditingMode(false);
    setExpanded(false);
  };

  const confirmSaving = () => {
    setShowCloseConfirmation(false);
    handleSubmitFormExecute();
  };

  const handleCloseConfirmation = () => setShowCloseConfirmation(false);

  useEffect(() => {
    setValue(FormFields.EditMode, editingMode);
  }, [editingMode]);

  useEffect(() => {
    reset(benefitsDataMapper(beneficiary, editingMode));
  }, [beneficiary]);

  return {
    confirmCloseWithoutSaving,
    confirmSaving,
    handleClose,
    handleSubmitFormExecute,
    form,
    showCloseConfirmation,
    handleCloseConfirmation,
    disableSubmit
  };
};
