import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'edenred-ui';
import { Trans, useTranslation } from 'react-i18next';

import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';

import * as loginActions from '../../actions/login';
import { FixedPanel } from '../../components/FixedPanel/FixedPanel';

import './ResetPasswordRequested.scss';

function ResetPasswordRequested() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="ResetPasswordRequested">
      <FixedPanel>
        <Container fluid>
          <Row>
            <Col xs={12}>
              <h2 className="small">
                <Trans
                  defaults={t('containers.reset_password_requested.page_body')}
                  components={{
                    p: <p />
                  }}
                />
              </h2>
            </Col>
          </Row>
          <Row className="button-margin">
            <Col xs={12} sm={{ span: 6, offset: 4 }}>
              <Button
                id="go-to-login-page-btn"
                onClick={() => {
                  dispatch(goToPage(routerPaths.login));
                }}
              >
                {t('containers.reset_password_requested.login_button')}
              </Button>
            </Col>
          </Row>
        </Container>
      </FixedPanel>
    </div>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...loginActions }, dispatch);

const ConnectedResetPasswordRequested = connect(
  null,
  mapDispatchToProps
)(ResetPasswordRequested);

export { ConnectedResetPasswordRequested as ResetPasswordRequested };
