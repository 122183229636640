import { dateRangeModes } from '@epi-constants/downloadReports';

export const formatReportRequestPayload = state => {
  const isColumnActive = columnId => {
    const column = state.columns.find(c => c.id === columnId);
    return column.active;
  };
  const isColumnGroupActive = columnId => {
    const column = state.columns.find(c => c.id === columnId);
    return state.dataScope[column.group];
  };

  const isColumnSelected = columnId =>
    isColumnGroupActive(columnId) && isColumnActive(columnId);

  const getStartDate = () =>
    state.dateRangeMode === dateRangeModes.datePicker
      ? state.pickerStartDate
      : state.startDate;
  const getEndDate = () =>
    state.dateRangeMode === dateRangeModes.datePicker
      ? state.pickerEndDate
      : state.endDate;

  return {
    startDate: getStartDate(),
    endDate: getEndDate(),
    benefits: state.activeBenefits.filter(b => b.checked).map(b => b.name),
    loadStatuses: ['fulfilled'],
    columns: {
      firstName: isColumnSelected('firstName'),
      lastName: isColumnSelected('lastName'),
      SSN: isColumnSelected('SSN'),
      benefitType: isColumnSelected('benefitType'),
      employeeAdded: isColumnSelected('benefitStart'),
      loadAmount: isColumnSelected('loadAmount'),
      loadDate: isColumnSelected('loadDate'),
      loadStatus: isColumnSelected('loadStatus'),
      costCenter: isColumnSelected('costCenter'),
      employeeNumber: isColumnSelected('employeeNumber')
    }
  };
};
