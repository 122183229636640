import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { LoadingSpinner } from 'edenred-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoading as isLoadingSelector } from '../../selectors/saga';
import * as navigationActions from '../../actions/navigation';
import './LoadingContainer.scss';

const LoadingContainer = ({ children, isLoading }) => (
  <div className="LoadingContainer">
    {isLoading && <LoadingSpinner />}
    <div className={`content ${isLoading ? 'loading' : ''}`}>{children}</div>
  </div>
);

LoadingContainer.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  spinnerSize: PropTypes.string
};

LoadingContainer.defaultProps = {
  children: ''
};

const mapStateToProps = (state, props) => ({
  isLoading: Array.isArray(props.isLoading)
    ? isLoadingSelector(props.isLoading)(state)
    : props.isLoading
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const ConnectedLoadingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingContainer);

export { ConnectedLoadingContainer as LoadingContainer };
