//@ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { PagePanel } from 'edenred-ui';

import { IProfile } from '@epi-models/containers/Profile';

import { ApiConstants } from '../../constants/actions';
import * as profileActions from '../../actions/profile';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { LoadingContainer } from '../../components/LoadingContainer/LoadingContainer';
import { UserInformation as UserInformationForm } from './UserInformation';
import { ChangePassword as ChangePasswordForm } from './ChangePassword';
import './Profile.scss';

const Profile = ({
  changeSelectedProfileTab,
  profile,
  isUserImpersonate
}: IProfile) => {
  const { t } = useTranslation();
  return (
    <PagePanel title={t('profile.title')}>
      <ErrorPanel failedActionName={ApiConstants.UPDATE_USER_INFORMATION} />
      <Tabs
        className="Profile"
        defaultIndex={profile.selectedProfileIndex}
        onSelect={tabIndex => changeSelectedProfileTab(tabIndex)}
      >
        <TabList>
          <Tab>{t('containers.user_profile.user_info_tab')}</Tab>
          {!isUserImpersonate && (
            <Tab>{t('containers.user_profile.change_password_tab')}</Tab>
          )}
        </TabList>
        <div className="col-lg-8 col-md-10 col-xs-12">
          <TabPanel>
            <LoadingContainer isLoading={[ApiConstants.GET_USER_INFORMATION]}>
              <UserInformationForm />
            </LoadingContainer>
          </TabPanel>
          {!isUserImpersonate && (
            <TabPanel>
              <ChangePasswordForm />
            </TabPanel>
          )}
        </div>
      </Tabs>
    </PagePanel>
  );
};

const mapStateToProps = state => ({
  profile: state.profile,
  isUserImpersonate: state.login.isUserImpersonate
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(profileActions, dispatch);

const ConnectedProfile = connect(mapStateToProps, mapDispatchToProps)(Profile);

export { ConnectedProfile as Profile };
