import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { Box, Text, Button, Tooltip, InfoIcon, ButtonMode } from 'edenred-ui';
import { useFormContext } from 'react-hook-form';

import TransferUnfulfilledRequestSummary from '@epi-components/TransferUnfulfilledRequestSummary/TransferUnfulfilledRequestSummary';
import { formatCurrencyDecimal } from '@epi-helpers/numeral';
import {
  TransferMoneyFormFields,
  normalizeDecimalComma
} from '@epi-containers/LoadCompanyAccount/helpers';
import { BoxOval } from '@epi-shared/components';
import {
  originalTotalSuggestedTransferSelector,
  isTransferSuggestedSelector
} from '@epi-selectors/transactionEstimationSelectors';
import { getActiveBenefits } from '@epi-actions/api';
import { selectActiveBenefits } from '@epi-selectors/benefitGroupSettings';

import { AmountField } from '../AmountField';
import { LUNCH } from '@epi-constants/benefitTypes';

export const TransferMoneyEnterAmount = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const amountFieldRef = useRef<HTMLInputElement>();
  const [estimationVisible, setEstimationVisible] = useState(false);
  const { setValue } = useFormContext();
  const activeBenefits = useSelector(selectActiveBenefits);
  const isTransferSuggested: boolean = useSelector(isTransferSuggestedSelector);
  const suggestedTransfer: number | undefined = useSelector(
    originalTotalSuggestedTransferSelector
  );

  const isLunariBenefitActive = !!activeBenefits.find(
    elem => elem === LUNCH.toLowerCase()
  );

  const toggleEstimationVisibility = () =>
    setEstimationVisible(!estimationVisible);

  useEffect(() => {
    amountFieldRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (estimationVisible) {
      setValue(
        TransferMoneyFormFields.Amount,
        normalizeDecimalComma(suggestedTransfer)
      );
    }
  }, [estimationVisible]);

  const toggleEstimationText = estimationVisible
    ? t('transfer_money.estimation_close')
    : t('transfer_money.estimation_open');

  const toggleEstimationIcon = estimationVisible
    ? 'mdi-chevron-up'
    : 'mdi-chevron-down';

  const tooltipText = isTransferSuggested ? (
    <Trans
      defaults={t('transfer_money.suggested_transfer_tooltip', {
        amount: formatCurrencyDecimal(suggestedTransfer)
      })}
    />
  ) : (
    t('transfer_money.enough_money_tooltip')
  );

  useEffect(() => {
    dispatch(getActiveBenefits());
  }, []);

  return (
    <Box minHeight={200} p={2} bgcolor={theme.primaryBackColorLight10}>
      <Box display="flex" alignItems="center" px={8} py={4}>
        <BoxOval text="1" bgColor={theme.gray0} />
        <Box flex={1}>
          <Box position="relative" py={5}>
            <Text>{t('transfer_money.enter_amount')}</Text>
            {isLunariBenefitActive && (
              <Box position="absolute" left={0} bottom={0}>
                <Button
                  id="toggle-estimation-visibility-btn"
                  type="button"
                  mode={ButtonMode.Link}
                  onClick={toggleEstimationVisibility}
                >
                  {toggleEstimationText}
                  <span style={{ verticalAlign: 'sub' }}>
                    <i className={`mdi mdi-24px ${toggleEstimationIcon}`} />
                  </span>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flex={1}>
          <Box maxWidth={200} mr={2}>
            <AmountField ref={amountFieldRef} />
          </Box>
          <Box>
            <Tooltip
              arrow
              placement="top"
              title={
                <Box maxWidth={274} p={2}>
                  <Text small>{tooltipText}</Text>
                </Box>
              }
            >
              <Box>
                <InfoIcon color={theme.primaryFrontColor} />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {estimationVisible && <TransferUnfulfilledRequestSummary />}
    </Box>
  );
};
