import { REHYDRATE } from 'redux-persist/constants';
import { camelCase } from 'lodash';
import createReducer from '../../helpers/CreateReducer';
import storage from '../../helpers/StorageHelper';
import { ApiConstants } from '../../constants/actions';
import { Roles } from '../../constants/roles';

const getRememberMeAuthenticationData = () => storage.get('rememberMe') || {};

export const getInitialState = (allowRememberMe = true) => ({
  isAuthenticated: false,
  isFetching: false,
  accessToken: '',
  tokenType: '',
  expiresIn: '',
  refreshToken: '',
  refreshingToken: false,
  validTo: null,
  roles: [],
  hasOrders: false,
  isUserImpersonate: false,
  isHasOrdersFetched: false,
  isUserUnverified: false,
  username: '',
  ...(allowRememberMe ? getRememberMeAuthenticationData() : {})
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOGIN_ACTION}_REQUEST`]: () => ({ isFetching: true }),
  [`${ApiConstants.LOGIN_ACTION}_SUCCESS`]: (state, { payload }) => ({
    accessToken: payload.accessToken,
    tokenType: payload.tokenType,
    expiresIn: payload.expiresIn,
    refreshToken: payload.refreshToken,
    isAuthenticated: true,
    isFetching: false,
    refreshingToken: false,
    isUserImpersonate: payload.isUserImpersonate
      ? payload.isUserImpersonate
      : false,
    validTo: payload.validTo,
    roles: (payload.roles || []).map(camelCase),
    isUserUnverified: false
  }),
  [`${ApiConstants.LOGIN_ACTION}_FAILURE`]: () => ({ isFetching: false }),
  [`${ApiConstants.LOGIN_ACTION}_UNVERIFIED`]: (state, { payload }) => ({
    isUserUnverified: true,
    username: payload.username,
    isFetching: false
  }),

  [`${ApiConstants.LOGOUT_ACTION}_REQUEST`]: () => ({ isFetching: true }),
  [`${ApiConstants.LOGOUT_ACTION}_SUCCESS`]: () => getInitialState(false),
  [`${ApiConstants.LOGOUT_ACTION}_FAILURE`]: () => ({
    isFetching: false,
    refreshingToken: false
  }),

  [`${ApiConstants.REFRESH_TOKEN}_REQUEST`]: () => ({ refreshingToken: true }),
  [`${ApiConstants.REFRESH_TOKEN}_SUCCESS`]: (state, { payload }) => ({
    ...state,
    isAuthenticated: true,
    validTo: payload.validTo,
    accessToken: payload.accessToken,
    refreshToken: payload.refreshToken,
    refreshingToken: false
  }),

  [`${ApiConstants.ADD_SIGNATORY_ROLE_TO_USER}_SUCCESS`]: state => ({
    ...state,
    roles: state.roles.concat(Roles.signatory)
  }),

  [`${ApiConstants.GET_HAS_ORDERS}_LOADED`]: (state, { payload }) => ({
    ...state,
    hasOrders: payload.hasOrders,
    isHasOrdersFetched: true
  }),

  [`${REHYDRATE}`](state, action) {
    return {
      ...state,
      ...action.payload.login,
      isFetching: false,
      isUserUnverified: false,
      username: '',
      rehydrated: true
    };
  }
});
