import { FormFields } from '@epi-forms/helpers';
import {
  IBeneficiaryData,
  ICurrentlySetBenefitValues
} from '@epi-models/pages/Beneficiaries';

export const benefitsDataMapper = (
  beneficiary: IBeneficiaryData,
  editingMode: boolean
) => {
  const {
    personId,
    firstName,
    lastName,
    emailAddress,
    personalId,
    endOfBenefit,
    endOfFunds,
    createdAt,
    employmentType,
    benefitGroupSettings,
    costCenter,
    employeeNumber
  } = beneficiary;

  return {
    [FormFields.FirstName]: firstName,
    [FormFields.LastName]: lastName,
    [FormFields.PersonID]: personId,
    [FormFields.EmailAddress]: emailAddress,
    [FormFields.PersonalID]: personalId,
    [FormFields.EndOfBenefit]: endOfBenefit,
    [FormFields.EndOfFunds]: endOfFunds,
    [FormFields.CreatedAt]: createdAt,
    [FormFields.EditMode]: editingMode,
    [FormFields.Benefits]: benefitGroupSettings,
    [FormFields.EmployeeType]: employmentType,
    [FormFields.EmployeeNumber]: employeeNumber || '',
    [FormFields.CostCenterNumber]: costCenter || ''
  };
};

export const setBenefitsDataForCreateEmployee = (
  currentlySetBenefitValues: ICurrentlySetBenefitValues
) => {
  const arrValues = Object.values(currentlySetBenefitValues);
  const arrKeys = Object.keys(currentlySetBenefitValues).map(key =>
    key.replace('card', '')
  );

  return arrKeys
    .map((key, index) => {
      if (!arrValues[index]) return;
      return {
        groupId: key + index,
        benefitType: key,
        maxAmountPerPeriod: arrValues[index] / 100,
        endDate: '',
        deductionType: '',
        frequency: '',
        mobilePaymentEnabled: false,
        name: key,
        startDate: ''
      };
    })
    .filter(obj => obj);
};

export const addBenefitsDataMapper = (
  currentlySetBenefitValues: ICurrentlySetBenefitValues
) => {
  return {
    [FormFields.FirstName]: '',
    [FormFields.LastName]: '',
    [FormFields.PersonalID]: '',
    [FormFields.EmailAddress]: '',
    [FormFields.EmployeeNumber]: '',
    [FormFields.CostCenterNumber]: '',
    [FormFields.EndOfBenefit]: '',
    [FormFields.EndOfFunds]: '',
    [FormFields.Benefits]: currentlySetBenefitValues?.cardLunch
      ? setBenefitsDataForCreateEmployee(currentlySetBenefitValues)
      : []
  };
};
