import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import {
  dateRangeModes,
  genericErrorMessage,
  reportDownloadSuccessMessage,
  requiredTypeForDownloadReport
} from '@epi-constants/downloadReports';
import { FormFields } from '@epi-forms/helpers';
import { useDispatch } from 'react-redux';
import { showNotification } from '@epi-actions/overlays';
import { fetchPost } from '@epi-helpers/FetchHelpers';
import { externalReportsApiUrl } from '@epi-repositories/ExternalReports';
import { downloadReport } from '@epi-helpers/reportsHelper';

import {
  getOther,
  formatReportRequestPayload,
  saveRequestedColumnsToStorage
} from '../helpers';

const isAtLeastOneEmployeeDataColumnSelected = (columns, dataScope) => {
  return (
    columns.some(c => {
      const requiredType = requiredTypeForDownloadReport.find(
        elem => elem === c.id
      );
      return c.group === 'employeeData' && c.active && requiredType;
    }) && dataScope.employeeData
  );
};

const isAtLeastOneBenefitTypeSelected = activeBenefits => {
  return activeBenefits.some(b => b.checked);
};

const isDateRangeSet = state => {
  if (state.dateRangeMode === dateRangeModes.datePicker) {
    return state.pickerStartDate && state.pickerEndDate;
  }
  return state.startDate && state.endDate;
};

export function useReportsTabFooter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    formState: { errors },
    getValues,
    watch
  } = useFormContext();

  const [isDownloading, setIsDownloading] = useState(false);
  const columns = getValues(getOther(FormFields.Columns));
  const dataScope = getValues(getOther(FormFields.DataScope));
  const activeBenefits = getValues(getOther(FormFields.ActiveBenefits));
  const otherValues = getValues(FormFields.Other);
  const isErrors = errors.other;

  const disableSubmit =
    !watch(getOther(FormFields.StartDate)) ||
    !watch(getOther(FormFields.EndDate)) ||
    isDownloading ||
    !isAtLeastOneEmployeeDataColumnSelected(columns, dataScope) ||
    !isAtLeastOneBenefitTypeSelected(activeBenefits) ||
    !isDateRangeSet(otherValues) ||
    !!isErrors;

  const onSubmit = () => {
    const values = getValues(FormFields.Other);
    saveRequestedColumnsToStorage(values.columns);
    setIsDownloading(true);

    const onSuccess = reportData => {
      dispatch(
        showNotification({ message: reportDownloadSuccessMessage }, 'success')
      );
      downloadReport(values, reportData);
      setIsDownloading(false);
    };
    const onFailure = () => {
      dispatch(showNotification({ message: genericErrorMessage }, 'error'));
      setIsDownloading(false);
    };

    fetchPost(
      externalReportsApiUrl,
      formatReportRequestPayload(values),
      onSuccess,
      onFailure
    );
  };

  return {
    otherReportsFooter: {
      submitText: t('containers.reports.download'),
      onSubmit,
      disableSubmit,
      submitButtonWidth: '200px'
    }
  };
}
