import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'edenred-ui';
import { useFormContext, useController } from 'react-hook-form';

import {
  FirstNameField,
  LastNameField,
  EmailAddressField
} from '@epi-forms/controls';
import { PersonalDataField } from '@epi-pages/Beneficiaries/components/BeneficiaryDetail/components';
import { TerminationDate } from '@epi-pages/Beneficiaries/components/EndOfBenefit/TerminationDate';
import { FormFields } from '@epi-forms/helpers';
import {
  maxLength,
  socialSecurityNumber,
  required as requiredValidation
} from '@epi-helpers/formHelpers/validators';

export const AddPersonalData = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const {
    field: { value: EoB }
  } = useController({
    name: FormFields.EndOfBenefit,
    control
  });

  return (
    <Box
      px={10}
      pt={5}
      gap={3}
      display="flex"
      flexDirection="column"
      minHeight={EoB ? 900 : 750}
      id="employee-benefit-add-personal-data-tab"
    >
      <FirstNameField />
      <LastNameField />
      {/* TODO add Employee type field here in epic EF-8986 */}
      <PersonalDataField
        label={t('containers.beneficiaries.detail.personal_id')}
        name={FormFields.PersonalID}
        validate={[requiredValidation, maxLength(50), socialSecurityNumber]}
        isAddMode
        required
      />
      <PersonalDataField
        label={t('containers.beneficiaries.detail.employee_number')}
        name={FormFields.EmployeeNumber}
        validate={[maxLength(20)]}
        isAddMode
      />
      <PersonalDataField
        label={t('containers.beneficiaries.detail.cost_center_number')}
        name={FormFields.CostCenterNumber}
        validate={[maxLength(50)]}
        isAddMode
      />
      <EmailAddressField
        // @ts-ignore
        label={t('containers.beneficiaries.table.email')}
      />
      <TerminationDate showTerminationButton />
    </Box>
  );
};
