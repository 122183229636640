import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  ButtonMode,
  SlideoutPanelComponent as Slideout,
  Box,
  SimpleButton,
  DeleteIcon,
  EditIcon
} from 'edenred-ui';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { pick } from 'lodash';

import {
  FirstNameField,
  LastNameField,
  EmailAddressField,
  PhoneNumberField
} from '@epi-forms/controls';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import { fetchPost as Post, fetchPut as Put } from '@epi-helpers/FetchHelpers';
import { contactsApiUrl } from '@epi-repositories/ContactsRepository';
import { showNotification } from '@epi-actions/overlays';
import { getSlideoutEditMode, getEditMode } from '@epi-selectors/company';
import { loadContacts } from '@epi-actions/api';
import {
  enableSlideoutEditMode,
  enableEditMode,
  disableEditMode
} from '@epi-actions/company';
import { useMenuDimensions } from '@epi-shared/hooks';
import config from '@epi-config';

import {
  FieldContainer,
  ButtonsContainer,
  StyledButton
} from './ContactPersonSlideoutStyles';

const { disableEditOptionsForOA } = config;

function ContactPersonSlideout({
  isSlideoutExpanded,
  closeSlideout,
  loadContacts,
  showError,
  showSuccess,
  editMode,
  enableEditMode,
  handleDeleteContact
}) {
  const { t } = useTranslation();
  const { isMobile } = useMenuDimensions();
  const [isAddContactProcessing, setIsAddContactProcessing] = useState(false);
  const isEditMode = useSelector(getEditMode);
  const dispatch = useDispatch();

  const {
    trigger,
    getValues,
    formState: { isValid, errors },
    reset
  } = useFormContext();
  const isActiveContact = getValues('personId');

  const saveContactPerson = () => {
    setIsAddContactProcessing(true);

    const onSuccess = () => {
      showSuccess();
      closeSlideout();
      reset();
      loadContacts();
      setIsAddContactProcessing(false);
    };
    const onFailure = () => {
      showError();
      setIsAddContactProcessing(false);
    };
    if (isActiveContact)
      return Put(contactsApiUrl, getValues(), onSuccess, onFailure);
    return Post(contactsApiUrl, getValues(), onSuccess, onFailure);
  };

  const getSlideoutTitle = () => {
    if (editMode && isActiveContact) return t('company_info.edit_contact');
    if (editMode) return t('company_info.add_contact');
    return t('company_info.preview_contact');
  };

  const handleDeleteAction = () => {
    handleDeleteContact({ ...getValues(), active: isActiveContact });
  };

  const handleCloseSlideout = () => {
    dispatch(disableEditMode());
    closeSlideout();
  };

  useEffect(() => {
    if (isEditMode) {
      trigger();
    }
  }, [isEditMode]);

  return (
    <Slideout
      width={isMobile ? '100vw' : '668px'}
      title={getSlideoutTitle()}
      expanded={isSlideoutExpanded}
      onClose={handleCloseSlideout}
      actions={
        editMode && (
          <ButtonsContainer>
            <StyledButton
              id="contact-person-close-btn"
              mode={ButtonMode.Link}
              onClick={handleCloseSlideout}
            >
              {t('company_info.back_btn')}
            </StyledButton>
            <StyledButton
              id="contact-person-save-btn"
              disabled={
                !isValid || !isEmptyObj(errors) || isAddContactProcessing
              }
              onClick={saveContactPerson}
            >
              {t('company_info.save_btn')}
            </StyledButton>
          </ButtonsContainer>
        )
      }
    >
      <Box
        py={isMobile ? '0' : '30px'}
        px={isMobile ? '0' : '60px'}
        display="flex"
        flexDirection={isMobile ? 'column-reverse' : 'row'}
      >
        <Box
          flex="1"
          sx={{
            '& input.Mui-disabled': {
              '-webkit-text-fill-color': '#252525'
            }
          }}
        >
          <FieldContainer>
            <FirstNameField readOnly={!editMode} />
          </FieldContainer>
          <FieldContainer>
            <LastNameField readOnly={!editMode} />
          </FieldContainer>
          <FieldContainer>
            <EmailAddressField
              readOnly={!editMode}
              label={t('controls.email_address_label')}
            />
          </FieldContainer>
          <FieldContainer>
            <PhoneNumberField readOnly={!editMode} />
          </FieldContainer>
        </Box>
        {!editMode && (
          <Box display={isMobile && 'flex'} justifyContent="end">
            <Box
              display="flex"
              width="185px"
              justifyContent={isActiveContact ? 'space-between' : 'end'}
            >
              <SimpleButton
                onClick={handleDeleteAction}
                icon={<DeleteIcon />}
                text={t('company_info.contacts.actions.delete')}
                disabled={Boolean(disableEditOptionsForOA)}
              />
              {isActiveContact && (
                <SimpleButton
                  disabled={Boolean(disableEditOptionsForOA)}
                  onClick={enableEditMode}
                  icon={<EditIcon />}
                  text={t('company_info.contacts.actions.edit')}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Slideout>
  );
}

ContactPersonSlideout.propTypes = {
  isSlideoutExpanded: PropTypes.bool.isRequired,
  closeSlideout: PropTypes.func.isRequired,
  loadContacts: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  enableEditMode: PropTypes.func.isRequired,
  handleDeleteContact: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  loadContacts: () => dispatch(loadContacts()),
  showError: () => {
    dispatch(showNotification({ message: 'messages.error' }, 'error'));
  },
  showSuccess: () => {
    dispatch(
      showNotification({ message: 'messages.company_info_updated' }, 'success')
    );
  },
  enableEditMode: () => {
    dispatch(enableSlideoutEditMode());
    dispatch(enableEditMode());
  }
});

const mapStateToProps = state => ({
  ...pick(state, ['apiCompany']),
  editMode: getSlideoutEditMode(state)
});

const ConnectedContactPersonSlideout = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPersonSlideout);

export { ConnectedContactPersonSlideout as ContactPersonSlideout };
