import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import {
  Button,
  ButtonMode,
  ButtonSize,
  DownloadIcon,
  InProgressIcon
} from 'edenred-ui';
import styled from 'styled-components';

import { isLoadingInvoice } from '../../selectors/api';
import * as apiActions from '../../actions/api';

const ButtonContent = styled.span`
  display: flex;
  align-items: center;
`;

const ButtonIcon = styled.span`
  width: 28px;
  display: flex;
  justify-content: center;
`;

const DownloadInvoiceButton = props => {
  const { isLoading, idType, downloadInvoice, id, children, mode } = props;
  const extraProps = pick(props, ['className']);
  const showSpinner = isLoading(idType, id);
  const params =
    idType === 'order' ? { voucherOrderId: id } : { paymentId: id };

  return (
    <div>
      <Button
        id="download-invoice-btn"
        mode={mode}
        size={ButtonSize.Medium}
        {...extraProps}
        disabled={showSpinner}
        onClick={() => downloadInvoice(params)}
      >
        <ButtonContent>
          {showSpinner ? (
            <InProgressIcon />
          ) : (
            <ButtonIcon>
              <DownloadIcon
                color={mode === ButtonMode.Primary ? 'white' : 'inherit'}
              />
            </ButtonIcon>
          )}
          {children && ' '}
          {children}
        </ButtonContent>
      </Button>
    </div>
  );
};

DownloadInvoiceButton.propTypes = {
  id: PropTypes.string.isRequired,
  downloadInvoice: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired,
  idType: PropTypes.oneOf(['order', 'payment']).isRequired,
  mode: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};

DownloadInvoiceButton.defaultProps = {
  children: null,
  mode: ButtonMode.Secondary
};

const mapStateToProps = state => ({
  isLoading: (idType, id) => isLoadingInvoice(idType, id)(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...apiActions }, dispatch);

const ConnectedDownloadInvoiceButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadInvoiceButton);

export { ConnectedDownloadInvoiceButton as DownloadInvoiceButton };
