import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from 'edenred-ui';

import { DownloadReceiptButton } from '@epi-components/DownloadReceiptButton/DownloadReceiptButton';
import SortingButton from '@epi-components/SortingButton';
import { getApplicationLocale } from '@epi-selectors/application';
import { DocumentSourceTypes } from '@epi-constants/documentSourceTypes';

import { TransactionsHistoryDateFormat } from '../../constants/dateFormats';
import { formatCurrencyBalance } from '../../helpers/numeral';
import { TransactionsHistorySorting } from '../../constants/transactionsHistorySorting';

import './TransactionsHistoryTable.scss';

const TransactionsHistoryTable = ({
  isLoading,
  sortBy,
  sortDirection,
  onSortingChange,
  transactions
}) => {
  const { t } = useTranslation();

  const loadingContent = (
    <tr className="loading text-center">
      <td colSpan="7">
        <LoadingSpinner />
      </td>
    </tr>
  );

  const noContent = () => (
    <tr className="no-content text-center">
      <td colSpan="7">{t('containers.transactions_history.no_data')}</td>
    </tr>
  );

  const getHistoryContent = transactions => {
    const transactionRows = transactions.map(row => [
      <tr className="standard-row" key={`standard${row.id}`}>
        <td>{Moment(row.date).format(TransactionsHistoryDateFormat)}</td>
        <td>
          {row.title}
          <br />
          <span className="hidden-xs subtitle">{row.description}</span>
        </td>
        <td>
          <div className="pull-right">
            {row.isDeposit && (
              <DownloadReceiptButton
                id="download-receipt-btn"
                sourceId={row.orderId || row.id}
                sourceType={
                  row.orderId !== null && row.orderId > 0
                    ? DocumentSourceTypes.Order
                    : DocumentSourceTypes.Transaction
                }
                className="btn"
              >
                <span>{t('containers.transactions_history.receipt')}</span>
              </DownloadReceiptButton>
            )}
          </div>
        </td>
        <td className="centered">{formatCurrencyBalance(row.balanceChange)}</td>
      </tr>
    ]);
    return transactionRows;
  };

  const getContent = () => {
    if (isLoading) {
      return loadingContent;
    }

    if (!transactions || transactions.length === 0) {
      return noContent();
    }
    return getHistoryContent(transactions);
  };

  return (
    <div className="TransactionsHistoryTable">
      <Table responsive size="sm" striped>
        <thead>
          <tr>
            <th>
              <SortingButton
                id="transactions-history-sort-by-date-btn"
                disabled={isLoading}
                sortBy={TransactionsHistorySorting.ByDate}
                sortedBy={sortBy}
                sortDirection={sortDirection}
                onSortingChange={onSortingChange}
              >
                {t('containers.transactions_history.date_col')}
              </SortingButton>
            </th>
            <th>
              <SortingButton
                id="transactions-history-sort-by-description-btn"
                disabled={isLoading}
                sortBy={TransactionsHistorySorting.ByDescription}
                sortedBy={sortBy}
                sortDirection={sortDirection}
                onSortingChange={onSortingChange}
              >
                {t('containers.transactions_history.transaction')}
              </SortingButton>
            </th>
            <th>&nbsp;</th>
            <th className="centered">
              <SortingButton
                id="transactions-history-sort-by-amount-btn"
                disabled={isLoading}
                sortBy={TransactionsHistorySorting.ByAmount}
                sortedBy={sortBy}
                sortDirection={sortDirection}
                onSortingChange={onSortingChange}
              >
                {t('containers.transactions_history.amount')}
              </SortingButton>
            </th>
          </tr>
        </thead>
        <tbody>{getContent()}</tbody>
      </Table>
    </div>
  );
};

TransactionsHistoryTable.propTypes = {
  transactions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.bool.isRequired,
  onSortingChange: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  locale: getApplicationLocale(state)
});

const TransactionsHistoryTableWithI18n = connect(mapStateToProps)(
  TransactionsHistoryTable
);

export { TransactionsHistoryTableWithI18n as TransactionsHistoryTable };
