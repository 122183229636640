import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, useController } from 'react-hook-form';

import { dateToApiFormat } from '@epi-helpers/dateHelper';
import { getCompanyMetadata } from '@epi-store/companyMetadata/companyMetadata.selectors';
import { FormFields } from '@epi-forms/helpers';
import { TODAY } from '@epi-constants/downloadReports';

export const useTerminationDateDialog = (handleClose: () => void) => {
  const { control } = useFormContext();
  const [date, setDate] = useState<null | Date>(null);
  const companyMetadata = useSelector(getCompanyMetadata);
  const endOfFundsPeriod = companyMetadata.data?.endOfFundsPeriod;

  const {
    field: { onChange: setEoF }
  } = useController({
    name: FormFields.EndOfFunds,
    control
  });

  const {
    field: { value: endOfBenefit, onChange }
  } = useController({
    name: FormFields.EndOfBenefit,
    control
  });

  const disableSave = () => {
    if (date === null) {
      return false;
    }

    if (date) {
      return date?.getTime() < TODAY.setHours(0, 0, 0, 0);
    }

    return true;
  };

  const handleSave = () => {
    if (date === null) {
      onChange('');
      setEoF('');
      handleClose();
      return;
    }

    onChange(dateToApiFormat(date));
    if (endOfFundsPeriod && date) {
      const endOfFounds = new Date(date);
      const newDate = endOfFounds.setDate(
        endOfFounds.getDate() + endOfFundsPeriod
      );

      setEoF(dateToApiFormat(newDate));
    }
    handleClose();
  };

  const handleSetDate = (value: Date | null) => setDate(value);

  useEffect(() => {
    if (endOfBenefit) {
      setDate(new Date(endOfBenefit));
    }
  }, []);

  return {
    handleSetDate,
    handleSave,
    endOfBenefit,
    disableSave
  };
};
