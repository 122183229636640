import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, Box } from 'edenred-ui';
import { FormProvider, useForm } from 'react-hook-form';

import { PasswordField, ConfirmPasswordField } from '@epi-forms/controls';
import { FormFields } from '@epi-forms/helpers';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import { getUserName } from '@epi-selectors/profile';
import {
  PasswordValidationContainer,
  PasswordValidation
} from '@epi-shared/components';
import { IChangePassword } from '@epi-models/containers/Profile';

import { changePassword as changePasswordLoadingSelector } from '../../selectors/loading';
import { ApiConstants } from '../../constants/actions';
import { required, password } from '../../helpers/formHelpers/validators';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import * as profileActions from '../../actions/profile';

function ChangePassword({
  submitting,
  sendingRequest,
  changePassword
}: IChangePassword) {
  const { t } = useTranslation();
  const username = useSelector(getUserName);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Password]: '',
      [FormFields.NewPassword]: '',
      [FormFields.ConfirmPassword]: ''
    }
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields }
  } = form;

  const onSubmit = values => {
    changePassword(values);
    setTimeout(() => {
      window.blur();
      reset();
    }, 0); // Run after rendering
  };

  const newPassword = watch(FormFields.NewPassword);
  const isUsernameSameAsPassword = username !== newPassword;

  const isSubmitBtnDisabled =
    [FormFields.NewPassword, FormFields.ConfirmPassword].some(
      field => !dirtyFields[field]
    ) ||
    !isEmptyObj(errors) ||
    submitting ||
    sendingRequest ||
    !isUsernameSameAsPassword;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ErrorPanel failedActionName={ApiConstants.CHANGE_PASSWORD} />
        <PasswordField
          name={FormFields.Password}
          label={t('forms.change_password.current_password')}
          validators={[required]}
        />
        <PasswordField
          displayValidationResults={false}
          showIconPassword
          name={FormFields.NewPassword}
          label={t('forms.change_password.new_password')}
          validators={[required, password]}
        />
        <PasswordValidationContainer password={newPassword} />
        <PasswordValidation
          isValid={isUsernameSameAsPassword}
          text={t('validation_messages.password_same_as_username')}
        />
        <ConfirmPasswordField
          label={t('forms.change_password.confirm_new_password')}
          field={FormFields.NewPassword}
        />
        <Box mt={3}>
          <Button
            id="change-password-submit-btn"
            type="submit"
            aria-hidden
            disabled={isSubmitBtnDisabled}
            className="pull-right"
            size={ButtonSize.Medium}
          >
            {t('forms.change_password.submit')}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}

const mapStateToProps = state => ({
  sendingRequest: changePasswordLoadingSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(profileActions, dispatch);

const ConnectChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);

export { ConnectChangePassword as ChangePassword };
