import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  reportDownloadSuccessMessage,
  genericErrorMessage
} from '@epi-constants/downloadReports';
import { arrayToObject } from '@epi-helpers/ArrayHelper';
import { fetchPost } from '@epi-helpers/FetchHelpers';
import { deductionReportsApiUrl } from '@epi-repositories/DownloadReportsRepository';
import { showNotification } from '@epi-actions/overlays';
import { downloadReport } from '@epi-helpers/reportsHelper';
import { FormFields } from '@epi-forms/helpers';

import { getPayroll, hasPayrollSelected } from '../helpers';
import { IPillData } from '@epi-models/pages/DownloadReports';

export const useDownloadReports = () => {
  const [isPosting, setIsPosting] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    formState: { errors },
    getValues,
    setValue,
    watch
  } = useFormContext();

  const isErrors = errors.payroll;
  const columns: IPillData[] = watch(getPayroll(FormFields.Columns));

  const disableSubmit =
    !watch(getPayroll(FormFields.StartDate)) ||
    !watch(getPayroll(FormFields.EndDate)) ||
    !hasPayrollSelected(columns) ||
    !!isErrors ||
    isPosting;

  const onSubmit = () => {
    const values = getValues(FormFields.Payroll);
    if (values?.columns) {
      setIsPosting(true);
      const columns = arrayToObject(values.columns, 'type');
      const isWageTypeActive =
        columns.wageType !== 'false' &&
        typeof values.wageType === 'string' &&
        values.wageType.length > 0;

      if (isWageTypeActive) {
        columns.wageType = values.wageType;
        delete values.wageType;
      } else {
        columns.wageType = 'false';
        delete values.wageType;
        setValue(getPayroll(FormFields.WageType), '');
      }

      const dataToSend = {
        ...values,
        loadStatuses: ['Fulfilled'],
        columns
      };

      const onSuccess = data => {
        dispatch(
          showNotification({ message: reportDownloadSuccessMessage }, 'success')
        );
        downloadReport(dataToSend, data, true);
        setIsPosting(false);
      };
      const onFailure = () => {
        dispatch(showNotification({ message: genericErrorMessage }, 'error'));
        setIsPosting(false);
      };

      fetchPost(deductionReportsApiUrl, dataToSend, onSuccess, onFailure);
    } else {
      dispatch(showNotification({ message: genericErrorMessage }, 'error'));
    }
  };

  const footer = {
    submitText: t('download_reports.lunch_deduction.download_button'),
    onSubmit,
    disableSubmit,
    submitButtonWidth: '200px'
  };

  return { footer };
};
